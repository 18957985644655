@use "../../abstract/placeholders";

.c-event-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    height: 100%;

    &__picture-container {
        position: relative;
        flex-shrink: 0;
        width: 100%;
        background-color: rgb(var(--rgb-white));

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border-bottom-right-radius: 100px;
            background-color: rgba(var(--rgb-lilac), 0.35);
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    &:hover &__picture-container::before {
        opacity: 1;
    }

    &__picture {
        width: 100%;
        overflow: hidden;
        border-bottom-right-radius: 100px;
        @extend %border-radius;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        min-width: 0;
        padding: 45px 30px;
        background-color: rgb(var(--rgb-white));
    }

    &__category-and-title {
        margin-bottom: var(--y-65);
    }

    &__category {
        display: block;
        width: fit-content;
        padding: 5px 15px;
        margin-bottom: 20px;
        border-radius: 36px;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));

        &.--light-orange { background-color: rgb(var(--rgb-light-orange)); }
        &.--green { background-color: rgb(var(--rgb-green)); }
        &.--yellow { background-color: rgb(var(--rgb-yellow)); }
        &.--lilac { background-color: rgb(var(--rgb-lilac)); }
        &.--blue { background-color: rgb(var(--rgb-blue)); }
    }

    &__title {
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 500px) { font-size: 1.9rem; }
    }

    &__infos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;
    }

    &__date,
    &__location,
    &__hour,
    &__cost {
        display: flex;
        width: calc(50% - 5px);

        svg {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            margin-right: 15px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2px;
        }

        span {
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
        }
    }

}