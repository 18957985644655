@use './label';

.c-select-field {
    --select-field-label-color: rgba(var(--rgb-eggplant), 0.7);
    --select-field-label-color-hover: rgb(var(--rgb-white));
    --select-field-label-color-active: rgb(var(--rgb-white));
    --select-field-label-background: rgb(var(--rgb-light-lilac));
    --select-field-label-background-hover: rgb(var(--rgb-eggplant));
    --select-field-label-background-active: rgb(var(--rgb-eggplant));
    --select-field-pointer-color: rgb(var(--rgb-eggplant));
    --select-field-pointer-color-hover: rgb(var(--rgb-white));
    --select-field-pointer-color-active: rgb(var(--rgb-white));
    --select-field-option-color: rgb(var(--rgb-eggplant));
    --select-field-option-color-hover: rgb(var(--rgb-eggplant));
    --select-field-option-color-selected: rgb(var(--rgb-eggplant));
    --select-field-option-background: rgb(var(--rgb-light-lilac));
    --select-field-option-background-hover: rgb(var(--rgb-lilac));
    --select-field-option-background-selected: rgb(var(--rgb-lilac));
    --select-field-scrollbar-color: rgb(var(--rgb-eggplant));
    --select-field-scrollbar-background: rgb(var(--rgb-light-lilac));
}

.c-select-field {
    position: relative;

    .tail-select {
        max-width: 500px;
        position: relative;
        z-index: 1;
        transition-property: z-index;

        &:not(.active) {
            transition-delay: 500ms;
        }

        &.active {
            z-index: 2;
        }

        svg {
            width: 14px;
            height: 14px;
            position: absolute;
            top: 50%;
            right: 22px;
            transform: translateY(-50%);
            fill: var(--select-field-pointer-color);
            z-index: 1;
            pointer-events: none;
            transition-property: transform, fill;
            transition-duration: 500ms;
            transition-timing-function: var(--out-cubic), linear;
        }

        &.active svg {
            fill: var(--select-field-pointer-color-active);
        }
    }

    .tail-select.active svg {
        transform: translateY(-50%) rotate(-180deg);
    }

    .tail-select:hover svg {
        fill: var( --select-field-pointer-color-hover);
    }

    .select-label {
        padding: 15px 60px 15px 22px;
        position: relative;
        background-color: var(--select-field-label-background);
        border: 0;
        z-index: 1;
        cursor: pointer;
        user-select: none;
        transition-property: color, background-color;
        transition-duration: 500ms;

        &:hover {
            background-color: var(--select-field-label-background-hover);
        }
    }

    .tail-select.active .select-label {
        background-color: var(--select-field-label-background-active);
    }

    .label-inner {
        display: block;
        position: relative;
        font-family: var(--ff);
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.3em;
        color: var(--select-field-label-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: color 300ms;

        > span {
            @extend .c-form-required-dot;
        }
    }

    .tail-select:hover .label-inner {
        color: var(--select-field-label-color-hover);
    }

    .tail-select.active .label-inner {
        color: var(--select-field-label-color-active);
    }

    .select-dropdown {
        width: 100%;
        position: absolute;
        top: 0%;
        left: 0;
        opacity: 0;
        background-color: rgb(var(--rgb-white));
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition-property: top, opacity, visibility;
        transition-duration: 500ms, 500ms, 0ms;
        transition-timing-function: var(--out-cubic), var(--out-cubic), linear;
        transition-delay: 0ms, 0ms, 500ms;
    }

    .tail-select.active .select-dropdown {
        opacity: 1;
        top: 100%;
        visibility: visible;
        pointer-events: all;
        transition-property: top, opacity, visibility;
        transition-duration: 500ms, 500ms, 0s;
        transition-delay: 0ms, 0ms, 0s;
        transition-timing-function: var(--out-cubic);
    }

    .dropdown-inner {
        max-height: 195px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: var(--select-field-scrollbar-background);
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--select-field-scrollbar-color);
        }
    }

    .dropdown-option {
        padding: 15px 22px;
        font-family: var(--ff);
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.3em;
        color: var(--select-field-option-color);
        background-color: var(--select-field-option-background);
        cursor: pointer;
        user-select: none;
        transition-property: color, background-color;
        transition-duration: 300ms;

        &:hover {
            color: var(--select-field-option-color-hover);
            background-color: var(--select-field-option-background-hover);
        }

        &.selected {
            color: var(--select-field-option-color-selected);
            background-color: var(--select-field-option-background-selected);
        }
    }

    select { // Select natif
        width: 100%;
        max-width: 500px;
        padding: 15px 60px 15px 22px;
        font-family: var(--ff);
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.3em;
        color: var(--select-field-label-color);
        border: 0;
        outline: none;
        background-image: url("../medias/images/icons/pointer.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 22px) 50%;
        background-size: 12px 12px;
        background-color: var(--select-field-label-background);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-appearance: none;

        &[data-select-hidden="0"] { // Permet de pouvoir scroller au select si le champs est requis et vide
            display: block !important;
            height: 0;
            padding: 0;
            margin: 0;
            opacity: 0;
        }
    }

    select::-ms-expand {
        display: none;
    }
}
