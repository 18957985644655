.c-button-share {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    min-width: 0;
    height: 45px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-orange));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-eggplant));
    }

    svg {
        width: 15px;
        height: 15px;
        fill: rgb(var(--rgb-white));
    }
}