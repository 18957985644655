/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    // #000000 - Noir
    --rgb-black: 0, 0, 0;
    // #ffffff - Blanc
    --rgb-white: 255, 255, 255;
    // #E13747 - Rouge
    --rgb-red: 225, 55, 71;
    // #483958 - Aubergine
    --rgb-eggplant: 72, 57, 88;
    // #20132D - Aubergine foncée
    --rgb-dark-eggplant: 32, 19, 45;
    // #F1794B - Orange
    --rgb-orange: 241, 121, 75;
    // #FEA167 - Orange pâle
    --rgb-light-orange: 254, 161, 103;
    // #EDEFF8 - Lilas pâle
    --rgb-light-lilac: 237, 239, 248;
    // #A0A9D3  - Lilas
    --rgb-lilac: 160, 169, 211;
    // #DFE2F0 - Mauve pâle
    --rgb-light-purple: 223, 226, 240;
    // #D3D7E8 - Mauve background
    --rgb-background-purple: 211, 215, 232;
    // #626262 - Gris
    --rgb-gray: 98, 98, 98;
    // #FFC713 - Jaune
    --rgb-yellow: 255, 199, 19;
    // #7ECFD2 - Bleu
    --rgb-blue: 126, 207, 210;
    // #9ECD5B - Vert
    --rgb-green: 158, 205, 91;
}
