@use "../../base/typography";

.c-overlay-menu {
    --back-button-height: 20px;
}

.c-overlay-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;
    transition: top var(--alerts-transition-duration) var(--alerts-transition-timing-function);

    &__container {
        display: flex;
        width: 100%;
        height: 100%;
        padding-top: var(--header-height);
        position: absolute;
        top: 0;
        left: 100%;
        overflow: hidden;
        background-color: rgb(var(--rgb-eggplant));
        transition: left 1000ms var(--in-out-quart);
    }

    &__scrollbar-container {
        width: 100%;
    }

    &__scrollbar {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-white), 0.25);
            border-radius: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        padding: 0 20px;
        min-height: 100%;
        opacity: 0;
        transform: translate3d(100px, 0, 0);
        transition-property: opacity, transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-quart);
        transition-delay: 200ms;
    }

    &__interfaces {
        position: relative;
        flex-grow: 1;
        transition-property: height;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart);
    }

    &__interface-container {
        width: 100%;
        height: 0px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        transition-property: height;
        transition-duration: 1ms;
        transition-delay: 1000ms;
    }

    &__interface-container:has(.is-active) {
        transition-delay: 0ms;
        height: 100%;
    }

    &__interface {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        pointer-events: none;

        &[data-menu-interface="main"] {
            justify-content: space-between;
        }

        &.is-active {
            transform: none;
            opacity: 1;
            pointer-events: all;
        }

        &.is-on-the-right {
            transform: translate3d(150%, 0, 0);
        }

        &.is-on-the-left {
            transform: translate3d(-150%, 0, 0);
        }

        &.has-animation {
            transition-property: opacity, transform;
            transition-duration: 700ms;
            transition-timing-function: var(--in-out-quart);
        }
    }

    &__primary-buttons-list {
        padding: 25px 0;
    }

    &__primary-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 350px;
        min-width: 0;
        padding-bottom: 20px;
        text-align: left;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        span {
            display: inline-block;
            margin-right: 15px;
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
        }

        svg {
            rotate: -90deg;
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }
    }

    &__bottom-list {
        padding-bottom: 55px;
    }

    &__bottom-list-item:not(:last-child) {
        margin-bottom: 13px;
    }

    &__bottom-link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        span {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
        }
    }

    &__back-button {
        display: flex;
        align-items: center;
        padding: 25px 0 15px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        svg {
            rotate: 90deg;
            flex-shrink: 0;
            width: 10px;
            height: 10px;
            margin-right: 15px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }

        span {
            font-size: 1.3rem;
            font-weight: 700;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));

            @media screen and (max-width: 768px)  { font-size: var(--fz-15); }
        }
    }

    &__title {
        width: 100%;
        max-width: 350px;
        padding-bottom: 45px;
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__links-list-item {
        margin-bottom: 20px;
    }

    &__link {
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        span {
            font-size: 1.7rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
        }
    }
}

.show-overlay-menu {
    .c-overlay-menu {
        pointer-events: all;
        visibility: visible;
    }

    body {
        --alerts-height: 0px;
    }

    .c-header {
        z-index: 30;

        &__menu-background {
            width: 100%;
        }

        &__left {
            z-index: -2;
        }

        &__button-hamburger-line,
        &__menu-background .c-header__button-hamburger-line {
            &:first-child,
            &:last-child {
                opacity: 0;
            }
            &:nth-child(2) {
                rotate: -45deg;
            }
            &:nth-child(3) {
                rotate: 45deg;
            }
        }
    }
}

.show-overlay-menu:not(.closing-overlay-menu) {
    .c-overlay-menu {
        &__container {
            left: 0%;
        }

        &__content {
            opacity: 1;
            transform: none;
            transition-delay: 500ms;
        }
    }
}


.show-overlay-menu.closing-overlay-menu {
    body {
        --alerts-height: 0px;
    }

    .c-header {
        z-index: 30;

        &__menu-background {
            width: 0;
        }
    }
}