@use "../base/typography";


.c-events-swiper {
    &__top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 0;
        padding-right: var(--x-100);
    }

    &__title {
        font-size: max(var(--fz-60), 4.5rem);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
    }

    &__buttons {
        display: flex;
        align-items: center;
        min-width: 0;
        @media screen and (max-width: 900px) { display: none; }

        &.--mobile {
            display: flex;
            padding-right: var(--x-100);
            margin-top: 55px;
            @media screen and (min-width: 901px) { display: none; }
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-1;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
        translate: 0 1px;
        @media screen and (max-width: 400px) { justify-content: center; }

        #desktop {
            @media screen and (max-width: 372px) { display: none; }
        }

        #mobile {
            display: none;
            @media screen and (max-width: 372px) { display: inline-block; }
        }
    }

    .c-button-calendar + &__button {
        margin-left: 20px;
        @media screen and (max-width: 400px) { margin-left: 10px; }
    }

    .swiper {
        padding-top: 40px;
    }

    .swiper-slide {
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }

    &__navigation-and-pagination {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 0;
        padding-top: 45px;
        padding-right: var(--x-100);
        @media screen and (max-width: 500px) { display: none; }

        &:has(.swiper-pagination-lock) {
            display: none;
        }
    }

    &__arrows-box {
        display: flex;
        align-items: center;
        min-width: 0;
    }

    &__arrow-prev,
    &__arrow-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-eggplant));
        transition: background-color 300ms;

        &:hover {
            background-color: rgb(var(--rgb-dark-eggplant));
        }

        svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }
    }

    &__arrow-prev + &__arrow-next {
        margin-left: 15px;
    }

    &__arrow-prev svg {
        rotate: -90deg;
    }

    &__arrow-next svg {
        rotate: 90deg;
    }

    .swiper-pagination {
        --swiper-pagination-color: rgba(var(--rgb-eggplant), 0.5);
        --swiper-pagination-left: auto;
        --swiper-pagination-right: auto;
        --swiper-pagination-bottom: auto;
        --swiper-pagination-top: auto;
        --swiper-pagination-bullet-size: auto;
        --swiper-pagination-bullet-width: auto;
        --swiper-pagination-bullet-height: 4px;
        --swiper-pagination-bullet-inactive-color: transparent;
        --swiper-pagination-bullet-inactive-opacity: 0;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-horizontal-gap: 0px;
        --swiper-pagination-bullet-vertical-gap: 0px;
        --swiper-pagination-bullet-border-radius: 0px;

        position: relative;
        display: flex;
        flex: 1;
        min-width: 0;
        margin-left: 65px;
        border-bottom: 1px solid rgba(var(--rgb-eggplant), 0.5);
        text-align: left;
    }

    .swiper-pagination-bullet {
        flex: 1;
        transition: background-color 300ms, opacity 300ms;
    }
}