@use "../../abstract/placeholders";

.c-large-documents {
    &:not(:last-child) {
        margin-bottom: var(--y-40);
    }
}

.c-large-document-card {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 0;
    min-height: 150px;
    padding: 30px var(--x-250);
    background-color: rgb(var(--rgb-light-lilac));
    -webkit-tap-highlight-color: transparent;
    transition: background-color 300ms;
    cursor: pointer;
    @media screen and (max-width: 1024px) { min-height: 120px; }
    @media screen and (max-width: 768px)  { min-height: 100px; }

    &:hover {
        background-color: rgb(var(--rgb-background-purple));
    }

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &__picture {
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        flex: 1;
        padding-right: 50px;
        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
    }

    &__svg-container {
        flex-shrink: 0;
        font-size: 0;
    }

    &__svg {
        width: 20px;
        height: 20px;
        fill: rgb(var(--rgb-eggplant));

        &.--job {
            rotate: 90deg;
            fill: none;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 1.5px;
        }
    }
}
