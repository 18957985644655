@use "../base/typography";

.c-events {
    --events-list-gap-x: 40px;
    @media screen and (max-width: 1200px) { --events-list-gap-x: 20px; }
    @media screen and (max-width: 900px) { --events-list-gap-x: 10px; }
    @media screen and (max-width: 800px) { --events-list-gap-x: 0px; }
    --events-list-gap-y: 65px;
    @media screen and (max-width: 600px) { --events-list-gap-y: 30px; }
}

.c-events {
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--events-list-gap-x) * 2));
        min-width: 0;
        margin-right: calc(0px - var(--events-list-gap-x));
        margin-bottom: calc(0px - var(--events-list-gap-y));
        margin-left: calc(0px - var(--events-list-gap-x));
    }

    &__list-item {
        width: 50%;
        padding: 0 var(--events-list-gap-x);
        margin-bottom: var(--events-list-gap-y);
        @media screen and (max-width: 800px) { width: 100%; }
    }

    &__active-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: max(var(--y-30), 25px);
    }

    &__active-filter-title {
        font-size: var(--fz-25);
        font-weight: 500;
        color: rgb(var(--rgb-eggplant));
        margin-right: 20px;
    }

    &__active-filter-button-refresh {
        display: flex;
    }

    &__active-filter-button-refresh-svg {
        width: 16px;
        height: 18px;
        stroke: rgb(var(--rgb-eggplant));
        transform-origin: center calc(50% + 1px);
        will-change: transform;
        transition: transform 300ms;
        margin-top: 1px;
    }

    &__active-filter-button-refresh:hover &__active-filter-button-refresh-svg {
        animation-name: rotation;
        animation-duration: 300ms;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}

@keyframes rotation {
    0% { transform: none; }
    100% { transform: rotate(-360deg); }
}
