:root {
    --cookies-button-size: 50px;
}

.c-button-cookies {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--cookies-button-size);
    height: var(--cookies-button-size);
    background-color: rgb(var(--rgb-eggplant));
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transition: background-color 300ms;
    z-index: 109;

    &:hover {
        background-color: rgb(var(--rgb-dark-eggplant));
    }

    &__svg {
        width: 20px;
        height: 20px;
        fill: white;
        transition: fill 300ms;
    }

    &:hover &__svg {
        fill: white;
    }
}
