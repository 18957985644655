@use "../../base/typography";


.c-overlay-fastlinks {
    --overlay-fastlinks-width: 400px;
    @media screen and (max-width: 600px) { --overlay-fastlinks-width: 100%; }
}

.c-overlay-fastlinks {
    position: fixed;
    top: var(--alerts-height);
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;

    &__container {
        position: absolute;
        top: 0;
        right: calc(0px - var(--overlay-fastlinks-width));
        width: var(--overlay-fastlinks-width);
        height: calc(var(--vh, 1vh) * 100);
        padding: 60px 11px 0 22px;
        background-color: rgb(var(--rgb-white));
        transition: right 800ms var(--out-cubic);
    }

    &__title {
        font-size: 2rem;
        font-weight: 700;
        color: rgb(var(--rgb-eggplant));
        margin-bottom: 25px;
    }

    &__list {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: calc(100% - 51px);
        padding-right: 11px;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-eggplant), 0.25);
            border-radius: 10px;
        }
    }

    &__list-item {
        margin-bottom: 10px;
    }

    .c-button-x {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 800ms;
        cursor: pointer;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-fastlinks {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-fastlinks {
        pointer-events: all;
        visibility: visible;

        &__container {
            right: 0;
            pointer-events: all;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.3);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-fastlinks.closing-overlay-fastlinks {
    .c-overlay-fastlinks {
        &__container {
            right: calc(0px - var(--overlay-fastlinks-width));
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}