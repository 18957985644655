.c-button-x {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    min-width: 0;
    height: 45px;
    border-radius: 50%;
    background-color: rgb(var(--rgb-eggplant));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-dark-eggplant));
    }

    svg {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 3px;
    }
}