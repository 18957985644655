.c-small-document-card {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 64px;
    min-width: 0;
    padding: 25px 40px;
    border: 1px solid rgba(var(--rgb-eggplant), 0.5);
    text-decoration: none;
    transition: background-color 300ms;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &:hover {
        background-color: rgb(var(--rgb-light-lilac));
    }

    &__picture {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        overflow: hidden;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
    }

    &__svg-container {
        flex-shrink: 0;
        font-size: 0;
        margin-right: 30px;
    }

    &__svg {
        width: 15px;
        height: 15px;
        fill: rgb(var(--rgb-eggplant));
    }
}
