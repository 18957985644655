@use '../base/typography';

.c-contact-infos {
    &__list {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        min-width: 0;
        padding-bottom: 40px;
        margin-right: -25px;
        margin-left: -25px;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 25px;
            height: 1px;
            background-color: rgba(var(--rgb-eggplant), 0.5);
        }
    }

    &__list + &__list {
        padding-top: 40px;
    }

    &__list-item {
        display: flex;
        align-items: center;
        min-width: 0;
        margin: 0 25px;

        span {
            font-size: var(--fz-18);
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
        }

        a {
            @extend .c-underlined-link;

            span {
                font-weight: 700;
            }
        }
    }

    &__svg-container {
        flex-shrink: 0;
        padding: 12px;
        margin-right: 10px;

        svg {
            width: 22px;
            height: 22px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2px;
        }
    }

    &__buttons {
        margin-top: 50px;
        display: flex;
        min-width: 0;
        @media screen and (max-width: 500px) { flex-direction: column; }
    }

    &__button {
        justify-content: center;

        &:first-child {
            @extend .c-button;
            @extend .c-button--full-1;
            @extend .c-button--svg-on-the-right;
            @extend .c-button--svg-stroke;

            svg {
                rotate: 180deg;
            }
        }
        &:last-child {
            @extend .c-button;
            @extend .c-button--empty-1;
            @extend .c-button--svg-on-the-right;
            @extend .c-button--svg-stroke;
            margin-left: 15px;
            @media screen and (max-width: 500px) {
                margin-top: 15px;
                margin-left: 0;
            }

            svg {
                rotate: 180deg;
            }
        }
    }
}