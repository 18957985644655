@use "../base/typography";

.c-sections {
    --sections-list-gap-x: 25px;
    @media screen and (max-width: 1000px) { --sections-list-gap-x: 15px; }
    --sections-list-gap-y: 55px;
    @media screen and (max-width: 600px) { --sections-list-gap-y: 30px; }
}

.c-sections {
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--sections-list-gap-x) * 2));
        min-width: 0;
        margin-right: calc(0px - var(--sections-list-gap-x));
        margin-bottom: calc(0px - var(--sections-list-gap-y));
        margin-left: calc(0px - var(--sections-list-gap-x));
    }

    &__list-item {
        width: 25%;
        padding: 0 var(--sections-list-gap-x);
        margin-bottom: var(--sections-list-gap-y);
        @media screen and (max-width: 1600px) { width: 33.33%; }
        @media screen and (max-width: 1200px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }
}