.c-breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-width: 0;

    &:has(.c-breadcrumb__list) {
        justify-content: space-between;
    }

    &__list {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__link-item,
    &__item {
        font-size: var(--fz-15);
        font-weight: 500;
        color: rgb(var(--rgb-eggplant));

        @media screen and (max-width: 768px) {
            font-size: 13px;
            line-height: 1.4em;
        }
    }

    &__link-item {
        transition-property: color;
        transition-duration: 300ms;

        &:hover {
            color: rgba(var(--rgb-eggplant), 0.6);
        }
    }

    &__space {
        translate: 0 -2px;
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0px 10px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-eggplant));
    }

    &__link-item:nth-child(2) &__space,
    &__item:nth-child(2) &__space {
        display: none;
    }

    &__button {
        display: flex;
        align-items: center;
        min-width: 0;
        margin-left: 20px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        span {
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
        }

        svg {
            translate: 0 -2px;
            flex-shrink: 0;
            width: 14px;
            height: 14px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2px;
            margin-left: 10px;
        }
    }
}