.c-header {
    position: relative;

    &__container {
        position: relative;
        isolation: isolate;
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 0;
        height: var(--header-height);
        padding: 0 22px;
        background-color: rgb(var(--rgb-light-lilac));
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: initial;
            justify-content: center;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 1px;
                background-color: rgb(var(--rgb-eggplant));
                opacity: 0.5;
            }
        }
    }

    &__menu-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: -1;
        width: 0;
        background-color: rgb(var(--rgb-eggplant));
        transition: width 1000ms var(--in-out-quart);
    }

    &__menu-background-content {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: min(100vw, var(--window-width));
        padding: 16px 22px 0;
        @media screen and (max-width: 500px) { padding: 19px 22px 0; }
    }

    &__left {
        position: relative;
        flex-shrink: 0;
        margin-right: var(--x-180);
        @media screen and (max-width: 1300px) { margin-right: var(--x-100); }
        @media screen and (max-width: 1200px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-width: 0;
            padding-bottom: 18px;
            margin-right: 0;
        }
    }

    &__logo-container {
        position: relative;
        display: block;
    }

    &__logo {
        width: var(--header-logo-width);
        @media screen and (max-width: 1200px) { display: none; }

        &.--mobile {
            display: block;
            width: 180px;
            @media screen and (min-width: 1201px) { display: none; }
        }
    }

    &__button-hamburger {
        will-change: transform;
        transition: opacity 300ms;
        @media screen and (min-width: 1201px) { display: none; }

        &:hover {
            opacity: 0.5;
        }
    }

    &__button-hamburger-lines {
        position: relative;
        width: 22px;
        height: 16px;
    }

    &__button-hamburger-line {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background-color: rgb(var(--rgb-eggplant));
        transition: translate 400ms var(--out-cubic), rotate 400ms var(--out-cubic), opacity 300ms;

        &:first-child {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
            translate: 0 -50%;
        }
        &:last-child {
            bottom: 0;
        }
    }

    &__menu-background-content &__button-hamburger-line {
        background-color: rgb(var(--rgb-white));
    }

    &__right {
        position: relative;
        flex: 1;
        @media screen and (max-width: 1200px) { flex: initial; }
    }

    &__top-section {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(var(--rgb-eggplant), 0.3);
        @media screen and (max-width: 1200px) { display: none; }
    }

    &__list {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 0;
        @media screen and (max-width: 1200px) { display: none; }
    }

    &__top-section &__list {
        justify-content: flex-end;
    }

    &__list-item:not(:last-child) {
        margin-right: 30px;
    }

    &__link {
        span {
            padding-bottom: 2px;
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 0);
            font-size: var(--fz-14);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
            transition: box-shadow 300ms;
        }

        &:hover span {
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 1);
        }
    }

    &__bottom-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 23px;
        @media screen and (max-width: 1200px) { padding-top: 0; }
    }

    &__button {
        display: flex;
        align-items: center;
        min-width: 0;
        padding-bottom: 2px;
        box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 0);
        transition: box-shadow 300ms;

        &:hover {
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 1);
        }

        span {
            margin-right: 10px;
            font-size: var(--fz-18);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
        }

        svg {
            width: 12px;
            height: 12px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 3px;
            transition: rotate 400ms var(--out-cubic);
        }
    }

    &__search {
        width: 100%;
        max-width: 430px;
        margin-left: 40px;
        @media screen and (max-width: 1200px) {
            max-width: 100%;
            margin-left: 0;
        }
    }

    .c-search-field {
        width: 100%;
        position: relative;

        .c-search-field__input {
            width: 100%;
            height: 100%;
            padding: 15px 80px 15px 30px;
            border: none;
            border-radius: 40px;
            background-color: rgb(var(--rgb-white));
            font-family: var(--ff);
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
            @media screen and (max-width: 500px) { padding: 11px 72px 11px 22.5px; }

            &::-webkit-input-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
            &::-moz-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
            &:-moz-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
            &::placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
        }

        .c-search-field__label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 15px 80px 15px 30px;
            border: none;
            border-radius: 40px;
            background-color: rgb(var(--rgb-white));
            font-family: var(--ff);
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            cursor: text;
            pointer-events: none;
            color: rgb(var(--rgb-eggplant));
            @media screen and (max-width: 500px) { padding: 11px 72px 11px 22.5px; }
        }

        .c-search-field__input:focus + .c-search-field__label,
        .c-search-field__input:not(:placeholder-shown) + .c-search-field__label {
            display: none !important;
        }

        .c-search-field__search-button {
            position: absolute;
            top: 50%;
            right: 30px;
            translate: 0 -50%;
            font-size: 0;
            @media screen and (max-width: 500px) { right: 28px; }

            svg {
                width: 15px;
                height: 15px;
                stroke: rgb(var(--rgb-eggplant));
                stroke-width: 1.5px;
                transition: opacity 300ms;
            }

            &:hover svg {
                opacity: 0.6;
            }
        }

        .c-search-field__x {
            position: absolute;
            top: 50%;
            right: 51px;
            translate: 0 calc(-50% - 2px);
            display: block;
            width: 12px;
            height: 12px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;
            font-family: initial;

            svg {
                width: 100%;
                height: 100%;
                stroke: rgb(var(--rgb-eggplant));
                stroke-width: 2px;
                transition: opacity 300ms;
            }

            &:hover svg {
                opacity: 0.6;
            }
        }

        &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
            opacity: 1;
            pointer-events: all;
        }
    }
}