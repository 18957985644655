@use "../base/typography";

.c-footer {
    &__breadcrumb {
        padding: 28px 40px;
        background-color: rgb(var(--rgb-light-purple));
        @media screen and (max-width: 700px) { padding: 28px 40px 28px 20px; }
    }

    &__container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        min-width: 0;
        padding: max(var(--y-65), 35px) var(--x-40) max(var(--y-65), 30px);
        background-color: rgb(var(--rgb-light-lilac));
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: initial;
        }
    }

    &__logo-container {
        display: block;
        @media screen and (max-width: 1000px) { margin-bottom: 30px; }
    }

    &__logo {
        width: 142px;
    }

    &__infos {
        display: flex;
        align-items: flex-end;
        min-width: 0;
        padding-bottom: 7px;
        @media screen and (max-width: 1300px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }
        @media screen and (max-width: 1000px) {
            padding-bottom: 0;
            margin-bottom: 30px;
        }
    }

    &__address-block + &__contact-block {
        margin-left: var(--x-70);
        @media screen and (max-width: 1300px) {
            margin-top: 25px;
            margin-left: 0;
        }
    }

    &__address,
    &__phone,
    &__email {
        display: block;
    }

    &__address,
    &__phone span,
    &__email span {
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 400px) { font-size: 1.3em; }
    }

    &__phone,
    &__email {
        span {
            padding-bottom: 2px;
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 0);
            transition: box-shadow 300ms;
        }

        &:hover span {
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 1);
        }
    }

    &__form {
        @media screen and (max-width: 500px) { width: 100%; }
    }

    &__form-label {
        display: block;
        margin-bottom: 10px;
        font-family: var(--ff);
        font-size: var(--fz-20);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 400px) { font-size: 1.7rem; }
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: rgba(var(--rgb-eggplant), 0.7); }
    ::-moz-placeholder { color: rgba(var(--rgb-eggplant), 0.7); }
    :-ms-input-placeholder {  color: rgba(var(--rgb-eggplant), 0.7); }
    :-moz-placeholder { color: rgba(var(--rgb-eggplant), 0.7); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: 1.6rem !important;
        font-weight: 700;
        -webkit-text-fill-color: rgb(var(--rgb-eggplant));
        caret-color: rgb(var(--rgb-eggplant));
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff);
        font-size: 1.6rem;
    }

    &__form-button {
        @extend .c-button;
        @extend .c-button--full-1;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-stroke;
        position: relative;
        padding: 19px 40px 18px;

        @media screen and (max-width: 400px) { padding: 16px; }
    }

    &__copyright {
        padding: 0 var(--x-40);
        background-color: rgb(var(--rgb-light-lilac));
    }

    &__copyright-content {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 0;
        padding: 20px 0;
        border-top: 1px solid rgba(var(--rgb-eggplant), 0.5);
        @media screen and (max-width: 700px) { justify-content: space-between; }
        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__copyright-left {
        display: flex;
        align-items: center;
        min-width: 0;
        margin-right: 40px;
        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: initial;
            margin-right: 0;
        }
    }

    &__copyright-item,
    &__copyright-link span {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
    }

    &__copyright-item {
        margin-right: 40px;
    }

    &__copyright-link {
        display: flex;
        align-items: center;
        min-width: 0;
        margin-bottom: -2px;
        @media screen and (max-width: 700px) {
            margin-top: 7px;
            font-size: var(--fz-12);
        }

        span {
            padding-bottom: 2px;
            margin-right: 10px;
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 0);
            transition: box-shadow 300ms;
        }

        .blanko {
            font-weight: 700;
        }

        &:hover span {
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 1);
        }

        svg {
            translate: 0 -2px;
            width: 17px;
            height: 17px;
            fill: rgb(var(--rgb-eggplant));
        }
    }

    &__copyright-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        min-width: 0;
        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: initial;
            flex: initial;
        }
    }

    &__sopfeu-link {
        display: flex;
        align-items: center;
        margin-bottom: -2px;
        transition: opacity 300ms;
        @media screen and (max-width: 700px) {
            order: 2;
            margin-top: 7px;
        }

        span {
            &:first-child {
                translate: 0 -2px;
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                background-color: #3ABC46;
                border: 2px solid #fff;
                border-radius: 100%;
                margin-right: 8px;
            }

            &:last-child {
                padding-bottom: 2px;
                box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 0);
                transition: box-shadow 300ms;
                font-family: var(--ff);
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 1.3em;
                color: rgb(var(--rgb-eggplant));
            }
        }

        &:hover span:last-child {
            box-shadow: inset 0 -0.13em 0 0 rgba(var(--rgb-orange), 1);
        }
    }
}