.c-alerts {
    --left-section-width: 150px;
    @media screen and (max-width: 700px) { --left-section-width: 75px; }
    @media screen and (max-width: 600px) { --left-section-width: 20px; }
    @media screen and (max-width: 400px) { --left-section-width: 0px; }
    --right-section-width: 60px;
    @media screen and (max-width: 600px) { --right-section-width: 10px; }
}

.c-alerts {
    overflow: hidden;
    height: var(--alerts-height);
    transition: height var(--alerts-transition-duration) var(--alerts-transition-timing-function);

    &__container {
        display: flex;
        width: 100%;
        min-width: 0;
        height: 100%;
        padding: 12px 25px;
        background-color: rgb(var(--rgb-red));
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        width: var(--left-section-width);
        min-width: 0;
        @media screen and (max-width: 400px) { display: none; }

        svg {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            fill: rgb(var(--rgb-white));
        }
    }

    &__swiper-pagination {
        translate: 0 2px;
        display: block;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        @media screen and (max-width: 700px) { display: none; }
    }

    &__arrows-box {
        display: flex;
        align-items: center;
        min-width: 0;
        @media screen and (max-width: 600px) { display: none; }
    }

    &__arrow-prev,
    &__arrow-next {
        flex-shrink: 0;
        font-size: 0;
        transition: opacity 300ms;

        &:hover { opacity: 0.6; }

        svg {
            width: 13px;
            height: 12px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 2px;
        }
    }

    &__arrow-prev svg {
        rotate: 90deg;
        margin-right: 12px;
    }

    &__arrow-next svg {
        rotate: -90deg;
    }

    &__center {
        width: calc(100% - var(--left-section-width) - var(--right-section-width));
        height: 100%;
        padding: 0 20px;
        @media screen and (max-width: 400px) { padding: 0 20px 0 0; }
    }

    &__swiper {
        overflow: hidden;
        height: 100%;
    }

    .swiper,
    .swiper-wrapper,
    .swiper-slide {
        height: 100%!important;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0;
        height: 100%;
    }

    &__item,
    &__link {
        display: flex;
        align-items: center;
        max-width: 100%;
    }

    &__item-text,
    &__link-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgb(var(--rgb-white));
    }

    &__item-text span,
    &__link-text span {
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.6em;
        color: rgba(var(--rgb-white));
        @media screen and (max-width: 400px) { font-size: 1.3em; }
    }

    &__item-text &__date,
    &__link-text &__date {
        font-weight: 700;
    }

    &__link-text {
        transition: opacity 300ms;
    }

    &__link:hover &__link-text {
        opacity: 0.6;
    }

    &__right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: var(--right-section-width);
        min-width: 0;
        height: 100%;
        padding-right: 25px;
    }

    &__close-button {
        display: flex;
        align-items: center;
        min-width: 0;
        height: 100%;
        transition: opacity 300ms;

        span {
            margin-right: 6px;
            font-size: 1.3rem;
            font-weight: 700;
            line-height: 1.6em;
            color: rgb(var(--rgb-white));
            @media screen and (max-width: 600px) { display: none; }
        }

        svg {
            translate: 0 -1px;
            flex-shrink: 0;
            width: 10px;
            height: 10px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 3px;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}

.show-overlay .c-alerts {
    transition-delay: 0ms;
}