.c-category-and-date {
    &__content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        min-width: 0;
        margin-bottom: 15px;
        margin-left: -20px;
        @media screen and (max-width: 400px) { margin-bottom: 10px; }
    }

    &__category {
        padding: 5px 15px;
        margin-bottom: 5px;
        margin-left: 20px;
        border-radius: 36px;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 600px) { font-size: 1.2rem; }
        @media screen and (max-width: 500px) { font-size: 1.1rem; }
        @media screen and (max-width: 400px) { font-size: 1rem; }

        &.--light-orange { background-color: rgb(var(--rgb-light-orange)); }
        &.--green { background-color: rgb(var(--rgb-green)); }
        &.--yellow { background-color: rgb(var(--rgb-yellow)); }
        &.--lilac { background-color: rgb(var(--rgb-lilac)); }
        &.--blue { background-color: rgb(var(--rgb-blue)); }
    }

    &__date {
        margin-bottom: 5px;
        margin-left: 20px;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.6em;
        color: rgba(var(--rgb-eggplant), 0.7);
        @media screen and (max-width: 700px) { font-size: 1.3rem; }
        @media screen and (max-width: 600px) { font-size: 1.2rem; }
        @media screen and (max-width: 500px) { font-size: 1.1rem; }
        @media screen and (max-width: 400px) { font-size: 1rem; }
    }
}