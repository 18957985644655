.c-publication-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    height: 100%;

    &__picture-container {
        position: relative;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-color: rgba(var(--rgb-lilac), 0.35);
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    &:hover &__picture-container::before {
        opacity: 1;
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 60%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        width: 100%;
        min-width: 0;
        min-height: 280px;
        padding: max(var(--x-45), 34px);
        border-bottom-right-radius: 30px;
        background-color: rgb(var(--rgb-light-lilac));
        @media screen and (max-width: 700px) { min-height: 270px; }
        @media screen and (max-width: 600px) { min-height: 260px; }
        @media screen and (max-width: 500px) { min-height: 250px; }
        @media screen and (max-width: 400px) { min-height: 230px; }
    }

    &__category-and-date {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        min-width: 0;
        margin-bottom: 15px;
        margin-left: -20px;
        @media screen and (max-width: 400px) { margin-bottom: 10px; }
    }

    &__category {
        padding: 5px 15px;
        margin-bottom: 5px;
        margin-left: 20px;
        border-radius: 36px;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 600px) { font-size: 13px; }

        &.--light-orange { background-color: rgb(var(--rgb-light-orange)); }
        &.--green { background-color: rgb(var(--rgb-green)); }
        &.--yellow { background-color: rgb(var(--rgb-yellow)); }
        &.--lilac { background-color: rgb(var(--rgb-lilac)); }
        &.--blue { background-color: rgb(var(--rgb-blue)); }
    }

    &__date {
        margin-bottom: 5px;
        margin-left: 20px;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.6em;
        color: rgba(var(--rgb-eggplant), 0.7);
        @media screen and (max-width: 700px) { font-size: 14px; }
    }

    &__title {
        overflow: hidden;
        margin-bottom: 20px;
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 500px) { font-size: 1.9rem; }
    }

    &__false-button {
        display: flex;
        align-items: center;
        min-width: 0;

        span {
            margin-right: 10px;
            font-size: var(--fz-15);
            font-weight: 700;
            line-height: 1.6em;
            color: rgb(var(--rgb-eggplant));
        }

        svg {
            rotate: 90deg;
            flex-shrink: 0;
            width: 14px;
            height: 14px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2px;
        }
    }
}