@use "../../abstract/placeholders";

.c-rubric-card {
    position: relative;
    background-color: rgb(var(--rgb-light-lilac));
    -webkit-tap-highlight-color: transparent;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 0;
        min-height: 150px;
        padding: 30px var(--x-250);
        transition: background-color 300ms;
        cursor: pointer;
        @media screen and (max-width: 1024px) { min-height: 120px; }
        @media screen and (max-width: 768px)  { min-height: 100px; }

        &:hover {
            background-color: rgb(var(--rgb-background-purple));
        }
    }

    &__picture {
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        @media screen and (max-width: 600px) { display: none; }
    }

    &__text {
        flex: 1;
        padding-right: 50px;
        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 12px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
    }

    &__plus-container {
        flex-shrink: 0;
    }

    &__plus {
        width: 20px;
        height: 20px;

        &::before,
        &::after {
            height: 2px;
            background-color: rgb(var(--rgb-eggplant));
            opacity: 1;
            transition-property: background-color, opacity;
            transition-duration: 300ms;
        }
    }

    &.js-accordion-opened &__plus {
        &::after {
            opacity: 0;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &__transition {
        transform: translateY(100px);
        padding: 0 var(--x-250);
        opacity: 0;
        transition: opacity 700ms var(--in-out-quart), transform 700ms var(--in-out-quart);
        margin-top: var(--y-30);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__transition > :first-child {
        margin-top: 0;
    }

    &__cms-content,
    &__documents,
    &__gallery,
    &__share {
        padding-bottom: var(--y-60);
    }

    &__share {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    &__share-button {
        display: inline-flex;
        align-items: center;
        min-width: 0;
        cursor: pointer;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.5;
        }

        span {
            font-family: var(--ff);
            font-size: var(--fz-15);
            font-weight: 700;
            color: rgb(var(--rgb-eggplant));
        }

        svg {
            width: 12px;
            height: 12px;
            margin-left: 10px;
            fill: rgb(var(--rgb-eggplant));
        }
    }

    &:not(.js-accordion-opened) {
        iframe {
            pointer-events: none;
        }
    }

    .c-small-document-card:hover,
    .s-cms-content a.docs:hover {
        background-color: rgb(var(--rgb-background-purple));
    }
}
