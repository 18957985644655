.c-section-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    height: 100%;

    &__picture-container {
        position: relative;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background-color: rgba(var(--rgb-lilac), 0.35);
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    &:hover &__picture-container::before {
        opacity: 1;
    }

    &:hover &__title {
        color: rgba(var(--rgb-eggplant), 0.6);
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 60%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__title {
        margin-top: 20px;
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
        transition-property: color;
        transition-duration: 300ms;
        @media screen and (max-width: 500px) { font-size: var(--fz-21); }
    }

}