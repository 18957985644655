.o-layout {
    display: flow-root;
    position: relative;
    z-index: 10;

    &.--page-events {
        background-color: rgb(var(--rgb-background-purple));
    }

    &__content {
        position: relative;
    }

    &__sticky-track {
        position: absolute;
        top: 0;
        bottom: calc(var(--cookies-button-size) / -2);
        left: 0;
        padding-left: var(--x-40);
        z-index: 10;

        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }

    &__sticky-button {
        position: sticky;
        bottom: 20px;
        align-self: flex-end;
    }
}