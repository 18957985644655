.o-section {
    &.--page-title {
        position: relative;
        z-index: 5;
        background-color: rgb(var(--rgb-light-lilac));
    }

    &.--page-content {
        position: relative;
        display: flow-root;

        .c-button-share {
            position: absolute;
            top: var(--y-70);
            left: calc(var(--x-250) / 2);
            translate: -50% 0;
            z-index: 10;
            @media screen and (max-width: 800px) { display: none; }
        }
    }

    &.--cms-content {
        margin-top: var(--y-70);
        margin-bottom: var(--y-65);
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }

    &.--category-and-date + &.--cms-content {
        margin-top: 0;
    }

    &.--small-documents {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }

    &.--documents {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &.--gallery {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }

    &.--rubrics {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &.--form {
        padding-top: var(--y-80);
        padding-bottom: var(--y-80);
        padding-right: var(--x-250);
        padding-left: var(--x-250);
        background-color: rgb(var(--rgb-background-purple));
    }

    &.--events-component {
        position: relative;
        isolation: isolate;
        margin-top: max(var(--y-100), 75px);
        padding-bottom: var(--y-175);
        padding-right: var(--x-100);
        padding-left: var(--x-100);
        background-color: rgb(var(--rgb-background-purple));

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: -1;
            height: 30%;
            background-color: rgb(var(--rgb-white));
        }
    }

    &.--publications-component {
        margin-top: max(var(--y-100), 75px);
        margin-bottom: var(--y-100);
        padding-right: var(--x-100);
        padding-left: var(--x-100);
    }

    &.--pagination {
        margin-top: var(--y-65);
        margin-bottom: var(--y-100);
        padding-right: var(--x-100);
        padding-left: var(--x-100);
    }

    &.--next-pagination {
        margin-top: 30px;
        margin-bottom: var(--y-100);
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }

    &.--showcase {
        margin-top: max(var(--y-100), 75px);
        margin-bottom: max(var(--y-100), 75px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.--events-swiper {
        position: relative;
        isolation: isolate;
        margin-top: max(var(--y-100), 75px);
        padding-bottom: var(--y-80);
        padding-left: var(--x-100);
        background-color: rgb(var(--rgb-background-purple));

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: -1;
            height: clamp(220px, 22.7vw, 330px);
            background-color: rgb(var(--rgb-white));
        }
    }

    &.--publications {
        margin-top: max(var(--y-70), 65px);
        margin-bottom: 65px;
        padding-right: var(--x-100);
        padding-left: var(--x-100);
    }

    &.--category-and-date {
        margin-top: var(--y-70);
        margin-bottom: 30px;
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }

    &.--events {
        margin-top: max(var(--y-70), 65px);
        margin-bottom: 65px;
        padding-right: var(--x-100);
        padding-left: var(--x-100);
    }

    &.--results {
        margin-top: var(--y-70);
        margin-bottom: 65px;
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }

    &.--contact-infos {
        margin-top: var(--y-70);
        margin-bottom: var(--y-70);
        padding-right: var(--x-250);
        padding-left: var(--x-250);
    }
}