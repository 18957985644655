/*
|--------------------------------------------------------------------------
| Les Placeholders
|--------------------------------------------------------------------------
|
| Les placeholders permettent de réutiliser certaines propriétés CSS.
|
*/

// Fix border radius safari
%border-radius {
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
