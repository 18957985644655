.c-overlay-dropdown {
    --section-list-gap-x: var(--x-nus-60);
}

.c-overlay-dropdown {
    position: absolute;
    top: calc(var(--alerts-height) + var(--header-height));
    transition: top var(--alerts-transition-duration) var(--alerts-transition-timing-function);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    visibility: hidden;
    pointer-events: none;
    @media screen and (max-width: 1200px) { display: none; }

    &__container {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 800ms;
        cursor: pointer;
    }

    &__sections {
        position: relative;
        overflow: hidden;
        width: calc(100% - 22px - var(--header-logo-width) - var(--x-180) + var(--x-50));
        height: 0;
        background-color: rgb(var(--rgb-eggplant));
        transition: height 900ms var(--in-out-quart);
        @media screen and (max-width: 1300px) { width: calc(100% - 22px - var(--header-logo-width) - var(--x-100) + var(--x-50)); }

        &::before,
        &::after {
            content: "";
            width: calc(100% - 10px);
            height: var(--y-70);
            background-color: rgb(var(--rgb-eggplant));
            position: absolute;
            left: 0;
            z-index: 5;
            pointer-events: none;
        }

        &::before {
            background: linear-gradient(180deg, rgba(var(--rgb-eggplant),1) 5%, rgba(var(--rgb-eggplant),0) 100%);
            top: 0;
        }

        &::after {
            background: linear-gradient(0deg, rgba(var(--rgb-eggplant),1) 5%, rgba(var(--rgb-eggplant),0) 100%);
            bottom: 0;
        }
    }

    .c-button-x {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
    }

    &__section {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        overflow-y: auto;
        overflow-x: hidden;
        translate: 0 -25px;
        opacity: 0;
        max-height: calc((var(--vh, 1vh) * 100) - var(--header-height));
        padding: var(--y-nus-65) var(--x-nus-50);
        pointer-events: none;
        transition: translate 400ms var(--out-cubic) 0ms, opacity 400ms 0ms;
    }

    &__section-list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--section-list-gap-x) * 2));
        min-width: 0;
        margin-right: calc(0px - var(--section-list-gap-x));
        margin-left: calc(0px - var(--section-list-gap-x));
    }

    &__section-list-item {
        width: 33.33%;
        padding: 0 var(--section-list-gap-x);
    }

    &__list-title {
        display: inline-block;
        padding-bottom: 25px;
        font-size: var(--fz-17);
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        opacity: 0.5;
        transition: opacity 300ms;

        &:hover {
            opacity: 1;
        }
    }

    &__list-item {
        padding-bottom: 15px;
    }

    &__page {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }
    }
}

.show-overlay-dropdown {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-dropdown {
        pointer-events: all;
        visibility: visible;
        z-index: 30;

        &__background {
            background-color: rgba(var(--rgb-black), 0.3);
        }
    }
}

/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin sectionDropdown($section) {
    .show-overlay-dropdown:not(.closing-overlay-dropdown)[data-section="#{$section}"] {
        .c-overlay-dropdown [data-section="#{$section}"] {
            translate: 0;
            opacity: 1;
            transition: translate 400ms var(--out-cubic) 300ms, opacity 400ms 300ms;
            pointer-events: all;
        }

        .c-header__button[data-section="#{$section}"] svg {
            rotate: 180deg;
        }
    }
}

@include sectionDropdown(municipalite);
@include sectionDropdown(loisirs-culture-et-vie-collective);
@include sectionDropdown(services);

.show-overlay-dropdown.closing-overlay-dropdown {
    .c-overlay-dropdown {
        &__background {
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}