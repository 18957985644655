@use "sass:math";

// LES TITRES ----------
// Très Gros titre (h1)
.c-huge-title {
    font-size: var(--fz-70);
    font-weight: 700;
    line-height: 1.3em;
}

// Gros titre (h2)
.c-large-title {
    font-size: var(--fz-40);
    font-weight: 700;
    line-height: 1.3em;
}

// Moyen titre (h3)
.c-medium-title {
    font-size: var(--fz-30);
    font-weight: 700;
    line-height: 1.3em;
}

// Petit titre (h4)
.c-small-title {
    font-size: var(--fz-25);
    font-weight: 700;
    line-height: 1.3em;
}


// LES TEXTES ----------
// Le paragraphe
.c-paragraph {
    font-size: var(--paragraph-font-size);
    line-height: var(--paragraph-line-height);
}

// L'introduction
.c-introduction  {
    font-size: var(--fz-25);
    line-height: 1.6em;
}

// La note
.c-note {
    font-family: var(--ff);
    font-size: var(--fz-14);
    line-height: 1.6em;
    color: rgb(var(--rgb-gray));
    white-space: normal;
}

// La citation
.c-quote {
    padding-left: 30px;
    border-left: 10px solid rgb(var(--rgb-orange));
    @media screen and (max-width: 1024px) {
        padding-left: 25px;
        border-width: 8px;
    }
    @media screen and (max-width: 768px)  {
        padding-left: 20px;
        border-width: 6px;
    }
    @media screen and (max-width: 500px)  {
        padding-left: 15px;
        border-width: 4px;
    }

    &, p {
        margin: 0;
        font-family: var(--ff);
        font-size: var(--fz-22);
        line-height: 1.5em;
        font-weight: 500;
    }
}


// LES LIENS ----------
// Le lien surligné
.c-underlined-link {
    @extend .c-paragraph;
    text-decoration: none;

    span {
        padding-bottom: 2px;
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-orange));
        transition: box-shadow 300ms var(--out-cubic);
        word-break: break-all;
    }

    &:hover span {
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-eggplant));
    }

    // Icones liens externes et téléchargement
    svg {
        margin-left: math.div(2, 17) + em;
        width: math.div(11, 17) + em;
        height: math.div(11, 17) + em;
        fill: rgb(var(--rgb-eggplant));
        transition: fill 300ms var(--out-cubic);
    }

    &:hover svg {
        fill: rgb(var(--rgb-eggplant));
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
        svg {
            width: math.div(12, 17) + em;
            height: math.div(12, 17) + em;
        }
    }
}


// LES BOUTONS ----------
// Le bouton plein et vide
.c-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    min-width: 0;
    padding: 19px 40px 20px;
    font-family: var(--ff);
    font-size: var(--fz-15);
    font-weight: 700;
    line-height: 1.3em;
    color: var(--button-color);
    text-align: left;
    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    border-radius: 45px;
    text-decoration: none;
    cursor: pointer;
    transition: color 300ms, background-color 300ms, border-color 300ms;

    @media screen and (max-width: 700px) {
        font-size: 14px;
        padding: 19px 35px 20px;
        line-height: 16px;
    }

    svg {
        --button-svg-dimension: 14px;
        --stroke-width: 2px;
        content: "";
        translate: 0 -1px;
        rotate: 90deg;
        display: inline-block;
        flex-shrink: 0;
        width: var(--button-svg-dimension);
        height: var(--button-svg-dimension);
    }

    &[target="_blank"] svg {
        --stroke-width: 1px;
        --button-svg-dimension: 11px;
        translate: 0 0;
        rotate: 0deg;
    }

    &[href$=".pdf"] svg,
    &[href$=".doc"] svg,
    &[href$=".zip"] svg {
        --stroke-width: 1px;
        --button-svg-dimension: 12px;
        translate: 0 0;
        rotate: 0deg;
    }

    &--svg-on-the-left {
        svg {
            margin-right: var(--button-svg-spacing, 10px);
        }
    }

    &--svg-on-the-right {
        svg {
            margin-left: var(--button-svg-spacing, 10px);
        }
    }

    &--svg-stroke {
        svg {
            stroke: var(--button-svg-color);
            stroke-width: var(--stroke-width, 1px);
            fill: none;
            transition: stroke 300ms;
        }
    }

    &--svg-fill {
        svg {
            fill: var(--button-svg-color);
            stroke: none;
            transition: fill 300ms;
        }
    }

    &--svg-both {
        svg {
            --stroke-width: 1.75px;
            stroke: var(--button-svg-color);
            stroke-width: var(--stroke-width, 1px);
            fill: var(--button-svg-color);
            transition: stroke 300ms, fill 300ms;
        }
    }

    // Eggplant to dark eggplant
    &--full-1 {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-eggplant));
        --button-border-color: rgb(var(--rgb-eggplant));
        transition: opacity 300ms, color 300ms, background-color 300ms, border-color 300ms;

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-dark-eggplant));
            --button-border-color: rgb(var(--rgb-dark-eggplant));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }

        &.disabled {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-eggplant));
            --button-border-color: rgb(var(--rgb-eggplant));
            opacity: 0.6;

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }

            &:hover {
                --button-color: rgb(var(--rgb-white));
                --button-background-color: rgb(var(--rgb-eggplant));
                --button-border-color: rgb(var(--rgb-eggplant));
                cursor: not-allowed;

                svg {
                    --button-svg-color: rgb(var(--rgb-white));
                }
            }
        }
    }

    // White to dark eggplant
    &--full-2 {
        --button-color: rgb(var(--rgb-eggplant));
        --button-background-color: rgb(var(--rgb-white));
        --button-border-color: rgb(var(--rgb-white));

        svg {
            --button-svg-color: rgb(var(--rgb-eggplant));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-dark-eggplant));
            --button-border-color: rgb(var(--rgb-dark-eggplant));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

    // Transparent to dark eggplant
    &--empty-1 {
        --button-color: rgb(var(--rgb-eggplant));
        --button-background-color: transparent;
        --button-border-color: rgb(var(--rgb-eggplant));

        svg {
            --button-svg-color: rgb(var(--rgb-eggplant));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-dark-eggplant));
            --button-border-color: rgb(var(--rgb-dark-eggplant));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }
}


// LES LISTES ----------
// Liste désordonnée et ordonnée
.c-list {
    li {
        @extend .c-paragraph;

        p {
            margin: 0;
        }

        &:not(:first-child) {
            margin-top: 12px;
        }

        ol,
        ul {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 0;

            li {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }

    &--unordered {
        --unordered-list-padding: 18px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 15px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                top: calc(var(--paragraph-line-height) / 2);
                transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
                background-color: rgb(var(--rgb-orange));
                border: 1px solid rgb(var(--rgb-orange));
                border-radius: 50%;
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    background-color: transparent;
                }

                & > ul li::before {
                    background-color: rgb(var(--rgb-orange));
                }
            }
        }
    }

    &--ordered {
        --ordered-list-padding: 50px;
        @media screen and (max-width: 768px) { --ordered-list-padding: 25px; }
        @media screen and (max-width: 500px) { --ordered-list-padding: 20px; }
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));
                font-weight: 700;
                color: rgb(var(--rgb-orange));
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }
}


// LES TABLEAUX ET ZONES ----------
// Le tableau
.c-table {
    // Ajouter cette variable si les bordures du tableau sont arrondies
    // --border-radius: 8px;
    overflow-x: auto;

    table {
        overflow-x: hidden;
        border-collapse: collapse;
        margin-right: 0 !important;

        tr {
            // Applique les styles pour toutes les cellules
            th,
            td {
                @extend .c-paragraph;

                padding: 12px 25px;
                border: 1px solid rgba(var(--rgb-eggplant), 0.7);

                & > :first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0 !important;
                }
            }

            // Applique les styles pour les cellules d'en-tête
            th,
            td.table-header {
                background-color: rgb(var(--rgb-eggplant));
                border-color: rgb(var(--rgb-white), 0.4);
                border-top-color: rgb(var(--rgb-eggplant));
                border-bottom-color: rgb(var(--rgb-eggplant));

                font-weight: 700;
                color: rgb(var(--rgb-white));
                text-align: left;

                &:first-child {
                    border-left-color: rgb(var(--rgb-eggplant));
                }

                &:last-child {
                    border-right-color: rgb(var(--rgb-eggplant));
                }
            }

            // Applique la couleur de background pour les cellules d'en-tête secondaire
            td.table-secondary-header {
                background-color: rgb(var(--rgb-light-lilac));
            }
        }
    }

    // Ajustement des styles du tableaux avec des bordures arrondies
    &--rounded-borders {

        table {
            border-collapse: separate;
            border-radius: var(--border-radius);

            // Le border-radius de la première cellule dans le thead
            thead tr:first-child th:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans le thead
            thead tr:first-child th:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:first-child,
            tbody:first-child tr:first-child td:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:last-child,
            tbody:first-child tr:first-child td:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la dernière rangée du tbody
            tr:last-child th:first-child:not(thead tr:last-child th:first-child),
            tr:last-child td:first-child:not(thead tr:last-child td:first-child) {
                border-bottom-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la dernière rangée du tbody
            tr:last-child th:last-child:not(thead tr:last-child th:last-child),
            tr:last-child td:last-child:not(thead tr:last-child td:last-child) {
                border-bottom-right-radius: var(--border-radius);
            }

            tr {
                // Ajustement des bordures à cause du border-collapse separate (Bordures horizontales en double)
                th,
                td {
                    &:not(:first-child) {
                        border-left: none;
                    }
                }
            }

            // Ajustement des bordures à cause du border-collapse separate (Bordures verticales en double)
            tr:not(:last-child) th,
            tr:not(:last-child) td {
                border-bottom: none;
            }
        }
    }
}

// Zone d'emphase
.c-emphasis {
    padding: var(--y-45) var(--x-40);
    border: 1px solid rgb(var(--rgb-orange));

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0
    }
}
