@use '../../base/typography';

.c-result-card {
    display: block;
    padding-bottom: 45px;
    border-bottom: 1px solid rgba(var(--rgb-eggplant), 0.5);
    font-weight: 700;

    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-width: 0;
    }

    &__title-and-description {
        padding-right: var(--x-80);
        @media screen and (max-width: 600px) { padding-right: 0; }
    }

    &__category {
        display: block;
        width: fit-content;
        padding: 5px 15px;
        margin-bottom: 15px;
        border-radius: 35px;
        border: 1px solid rgba(var(--rgb-eggplant), 0.5);
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
    }

    &__title {
        margin-bottom: 15px;
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));

        mark {
            padding: 2px;
            background-color: rgb(var(--rgb-background-purple));
        }
    }

    &__description {
        margin-bottom: 25px;
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));

        mark {
            padding: 2px;
            background-color: rgb(var(--rgb-background-purple));
        }
    }

    &__button {
        @extend .c-underlined-link;
        pointer-events: none;
    }

    &:hover &__button span {
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-eggplant));
    }

    &__picture-container {
        flex-shrink: 0;
        width: 90px;
        @media screen and (max-width: 600px) { display: none; }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 100%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}