/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    // Générer les variables CSS des font-sizes
    @include fontSizes(
        "14", "15", "16", "17", "18", "20", "21", "22", "25", "30", "40", "60", "65", "70", "80",
    );

    // Générer les variables CSS des espacements
    @include spacings(
        "x-25", "x-30", "x-40", "x-45", "x-50", "x-60", "x-70", "x-75", "x-80", "x-100", "x-180", "x-250",
        "y-20", "y-25", "y-30", "y-35", "y-40", "y-45", "y-50", "y-60", "y-65", "y-70", "y-80", "y-90", "y-100", "y-125", "y-175"
    );

    // Variables CSS
    --paragraph-font-size: var(--fz-17);
    --paragraph-line-height: 1.6em;
    --tooltip-dimensions: 18px;
    --header-height: 132px;
    @media screen and (max-width: 1200px) { --header-height: 125px; }
    --header-logo-width: 142px;
    --button-calendar-dimensions: 58px;
    --alerts-height: 0px;
    --alerts-transition-duration: 500ms;
    --alerts-transition-timing-function: var(--in-out-cubic);
}
