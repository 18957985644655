/*
|--------------------------------------------------------------------------   
| La variable des espacements
|--------------------------------------------------------------------------
|
| Mapping des espacements sur l'axe des X et Y à l'intérieur de la
| variable $spacings. Les espacements sont convertis en variable CSS dans
| le mixin spacings étant dans abstract/mixins.scss qui celui-ci est
| appelé dans le fichier base/root.scss. La map contient deux niveaux
| d'imbrication. Le premier niveau de la map est l'orientation de
| l'espacement (x ou y) et le deuxième contient valeur de base (value), la
| valeur minimale (min) et la valeur maximale (max).
|
| Il faut donner un chiffre sans unité de mesure pour la valeur de base.
| Il faut donner un chiffre avec une unité de mesure en pixel ou en
| pourcentage pour la valeur minimale et maximale.
|
| $spacings: (
|     "x": (
|         "70": (
|             "value": 70,
|             "min": 22%,
|             "max": 110%,
|         )
|     )
| );
|
*/

$spacings: (
    "x": (
        "300": (
            "value": 300,
            "min": 22px,
            "max": 110%
        ),
        "295": (
            "value": 295,
            "min": 22px,
            "max": 110%
        ),
        "290": (
            "value": 290,
            "min": 22px,
            "max": 110%
        ),
        "285": (
            "value": 285,
            "min": 22px,
            "max": 110%
        ),
        "280": (
            "value": 280,
            "min": 22px,
            "max": 110%,
        ),
        "275": (
            "value": 275,
            "min": 22px,
            "max": 110%
        ),
        "270": (
            "value": 270,
            "min": 22px,
            "max": 110%
        ),
        "265": (
            "value": 265,
            "min": 22px,
            "max": 110%
        ),
        "260": (
            "value": 260,
            "min": 22px,
            "max": 110%
        ),
        "255": (
            "value": 255,
            "min": 22px,
            "max": 110%
        ),
        "250": (
            "value": 250,
            "min": 22px,
            "max": 110%
        ),
        "245": (
            "value": 245,
            "min": 22px,
            "max": 110%
        ),
        "240": (
            "value": 240,
            "min": 22px,
            "max": 110%
        ),
        "235": (
            "value": 235,
            "min": 22px,
            "max": 110%
        ),
        "230": (
            "value": 230,
            "min": 22px,
            "max": 110%
        ),
        "225": (
            "value": 225,
            "min": 22px,
            "max": 110%
        ),
        "220": (
            "value": 220,
            "min": 22px,
            "max": 110%
        ),
        "215": (
            "value": 215,
            "min": 22px,
            "max": 110%
        ),
        "210": (
            "value": 210,
            "min": 22px,
            "max": 110%
        ),
        "205": (
            "value": 205,
            "min": 22px,
            "max": 110%
        ),
        "200": (
            "value": 200,
            "min": 22px,
            "max": 110%
        ),
        "195": (
            "value": 195,
            "min": 22px,
            "max": 110%
        ),
        "190": (
            "value": 190,
            "min": 22px,
            "max": 110%
        ),
        "185": (
            "value": 185,
            "min": 22px,
            "max": 110%
        ),
        "180": (
            "value": 180,
            "min": 22px,
            "max": 110%,
        ),
        "175": (
            "value": 175,
            "min": 22px,
            "max": 110%
        ),
        "170": (
            "value": 170,
            "min": 22px,
            "max": 110%
        ),
        "165": (
            "value": 165,
            "min": 22px,
            "max": 110%
        ),
        "160": (
            "value": 160,
            "min": 22px,
            "max": 110%
        ),
        "155": (
            "value": 155,
            "min": 22px,
            "max": 110%
        ),
        "150": (
            "value": 150,
            "min": 22px,
            "max": 110%
        ),
        "145": (
            "value": 145,
            "min": 22px,
            "max": 110%
        ),
        "140": (
            "value": 140,
            "min": 22px,
            "max": 110%
        ),
        "135": (
            "value": 135,
            "min": 22px,
            "max": 110%
        ),
        "130": (
            "value": 130,
            "min": 22px,
            "max": 110%
        ),
        "125": (
            "value": 125,
            "min": 22px,
            "max": 110%
        ),
        "120": (
            "value": 120,
            "min": 22px,
            "max": 110%
        ),
        "115": (
            "value": 115,
            "min": 22px,
            "max": 110%
        ),
        "110": (
            "value": 110,
            "min": 22px,
            "max": 110%
        ),
        "105": (
            "value": 105,
            "min": 22px,
            "max": 110%
        ),
        "100": (
            "value": 100,
            "min": 22px,
            "max": 110%
        ),
        "95": (
            "value": 95,
            "min": 22px,
            "max": 110%
        ),
        "90": (
            "value": 90,
            "min": 22px,
            "max": 110%
        ),
        "85": (
            "value": 85,
            "min": 22px,
            "max": 110%
        ),
        "80": (
            "value": 80,
            "min": 22px,
            "max": 110%
        ),
        "75": (
            "value": 75,
            "min": 22px,
            "max": 110%
        ),
        "70": (
            "value": 70,
            "min": 22px,
            "max": 110%
        ),
        "65": (
            "value": 65,
            "min": 22px,
            "max": 110%
        ),
        "60": (
            "value": 60,
            "min": 22px,
            "max": 110%
        ),
        "55": (
            "value": 55,
            "min": 22px,
            "max": 110%
        ),
        "50": (
            "value": 50,
            "min": 22px,
            "max": 110%
        ),
        "45": (
            "value": 45,
            "min": 22px,
            "max": 110%
        ),
        "40": (
            "value": 40,
            "min": 22px,
            "max": 110%
        ),
        "30": (
            "value": 30,
            "min": 22px,
            "max": 110%
        ),
        "25": (
            "value": 25,
            "min": 22px,
            "max": 110%
        ),
        "22": (
            "value": 22,
            "min": 22px,
            "max": 110%
        ),
        "20": (
            "value": 20,
            "min": 20px,
            "max": 110%
        )
    ),
    "y": (
        "300": (
            "value": 300,
            "min": 100px,
            "max": 110%
        ),
        "295": (
            "value": 295,
            "min": 100px,
            "max": 110%
        ),
        "290": (
            "value": 290,
            "min": 100px,
            "max": 110%
        ),
        "285": (
            "value": 285,
            "min": 100px,
            "max": 110%
        ),
        "280": (
            "value": 280,
            "min": 95px,
            "max": 110%
        ),
        "275": (
            "value": 275,
            "min": 95px,
            "max": 110%
        ),
        "270": (
            "value": 270,
            "min": 95px,
            "max": 110%
        ),
        "265": (
            "value": 265,
            "min": 90px,
            "max": 110%
        ),
        "260": (
            "value": 260,
            "min": 90px,
            "max": 110%
        ),
        "255": (
            "value": 255,
            "min": 90px,
            "max": 110%
        ),
        "250": (
            "value": 250,
            "min": 85px,
            "max": 110%
        ),
        "245": (
            "value": 245,
            "min": 85px,
            "max": 110%
        ),
        "240": (
            "value": 240,
            "min": 85px,
            "max": 110%
        ),
        "235": (
            "value": 235,
            "min": 80px,
            "max": 110%
        ),
        "230": (
            "value": 230,
            "min": 80px,
            "max": 110%
        ),
        "225": (
            "value": 225,
            "min": 80px,
            "max": 110%
        ),
        "220": (
            "value": 220,
            "min": 75px,
            "max": 110%
        ),
        "215": (
            "value": 215,
            "min": 75px,
            "max": 110%
        ),
        "210": (
            "value": 210,
            "min": 75px,
            "max": 110%
        ),
        "205": (
            "value": 205,
            "min": 70px,
            "max": 110%
        ),
        "200": (
            "value": 200,
            "min": 70px,
            "max": 110%
        ),
        "190": (
            "value": 190,
            "min": 70px,
            "max": 110%
        ),
        "185": (
            "value": 185,
            "min": 65px,
            "max": 110%
        ),
        "180": (
            "value": 180,
            "min": 65px,
            "max": 110%
        ),
        "175": (
            "value": 175,
            "min": 65px,
            "max": 110%
        ),
        "170": (
            "value": 170,
            "min": 60px,
            "max": 110%
        ),
        "165": (
            "value": 165,
            "min": 60px,
            "max": 110%
        ),
        "160": (
            "value": 160,
            "min": 60px,
            "max": 110%
        ),
        "155": (
            "value": 155,
            "min": 60px,
            "max": 110%
        ),
        "150": (
            "value": 150,
            "min": 55px,
            "max": 110%
        ),
        "145": (
            "value": 145,
            "min": 55px,
            "max": 110%
        ),
        "140": (
            "value": 140,
            "min": 55px,
            "max": 110%
        ),
        "135": (
            "value": 135,
            "min": 55px,
            "max": 110%
        ),
        "130": (
            "value": 130,
            "min": 50px,
            "max": 110%
        ),
        "125": (
            "value": 125,
            "min": 50px,
            "max": 110%
        ),
        "120": (
            "value": 120,
            "min": 50px,
            "max": 110%
        ),
        "115": (
            "value": 115,
            "min": 50px,
            "max": 110%
        ),
        "110": (
            "value": 110,
            "min": 45px,
            "max": 110%
        ),
        "105": (
            "value": 105,
            "min": 45px,
            "max": 110%
        ),
        "100": (
            "value": 100,
            "min": 45px,
            "max": 110%
        ),
        "95": (
            "value": 95,
            "min": 45px,
            "max": 110%
        ),
        "90": (
            "value": 90,
            "min": 40px,
            "max": 110%
        ),
        "85": (
            "value": 85,
            "min": 40px,
            "max": 110%
        ),
        "80": (
            "value": 80,
            "min": 40px,
            "max": 110%
        ),
        "75": (
            "value": 75,
            "min": 40px,
            "max": 110%
        ),
        "70": (
            "value": 70,
            "min": 38px,
            "max": 110%
        ),
        "65": (
            "value": 65,
            "min": 36px,
            "max": 110%
        ),
        "60": (
            "value": 60,
            "min": 34px,
            "max": 110%
        ),
        "55": (
            "value": 55,
            "min": 32px,
            "max": 110%
        ),
        "50": (
            "value": 50,
            "min": 30px,
            "max": 110%
        ),
        "45": (
            "value": 45,
            "min": 28px,
            "max": 110%
        ),
        "40": (
            "value": 40,
            "min": 26px,
            "max": 110%
        ),
        "35": (
            "value": 35,
            "min": 24px,
            "max": 110%
        ),
        "30": (
            "value": 30,
            "min": 22px,
            "max": 110%
        ),
        "25": (
            "value": 25,
            "min": 20px,
            "max": 110%
        ),
        "20": (
            "value": 20,
            "min": 16px,
            "max": 110%
        ),
    )
);