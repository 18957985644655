@use "../../base/typography";

.c-overlay-popup {
    --overlay-popup-width: 520px;
    --overlay-popup-padding: 85px 40px;
    @media screen and (max-width: 1200px) { --overlay-popup-padding: 85px 30px; }
    @media screen and (max-width: 1024px) { --overlay-popup-padding: 85px 22px 50px;}
}

.c-overlay-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 130;

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        transform: translate(-50%, -40%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--overlay-popup-width);
        max-width: calc(100% - var(--x-40) - var(--x-40));
        max-height: calc(var(--vh, 1vh) * 90);
        padding: var(--overlay-popup-padding);
        background-color: rgb(var(--rgb-white));
        opacity: 0;
        pointer-events: none;
        transition: transform 800ms var(--in-out-quart), opacity 800ms var(--in-out-quart);
    }

    .c-button-x {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        margin-bottom: 20px;
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
        text-align: center;
    }

    &__description {
        @extend .c-paragraph;
        text-align: center;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-1;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
        margin-top: var(--y-40);
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 800ms;
        cursor: pointer;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-popup {
    .c-overlay-popup {
        visibility: visible;
        pointer-events: all;

        &__container {
            opacity: 1;
            transform: translate(-50%, -50%);
            pointer-events: all;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.3);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-popup.closing-overlay-popup {
    .c-overlay-popup {
        &__container {
            opacity: 0;
            transform: translate(-50%, -40%);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
