@use "../../base/typography";


.c-overlay-calendar {
    --overlay-calendar-width: 580px;
    @media screen and (max-width: 600px) { --overlay-calendar-width: 100%; }
    --infos-height: 90px;
}

.c-overlay-calendar {
    position: fixed;
    top: var(--alerts-height);
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    z-index: 20;

    &__container {
        position: absolute;
        top: 0;
        right: calc(0px - var(--overlay-calendar-width));
        width: var(--overlay-calendar-width);
        height: calc(var(--vh, 1vh) * 100);
        padding: 85px 0 var(--infos-height);
        background-color: rgb(var(--rgb-white));
        transition: right 800ms var(--out-cubic);
    }

    .c-button-x {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__calendar-zone {
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 var(--x-75);
        @media screen and (max-height: 800px) { justify-content: initial; }

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(var(--rgb-eggplant), 0.25);
            border-radius: 10px;
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-1;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
        margin-top: var(--y-30);
        margin-bottom: 20px;
    }

    &__infos {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--infos-height);
        padding: 30px;
        border-top: 1px solid rgba(var(--rgb-eggplant), 0.50);
        @media screen and (max-width: 400px) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

    &__infos-title {
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 600px) { display: none; }
    }

    &__list,
    &__list-item {
        display: flex;
        align-items: center;
    }

    &__list {
        flex-wrap: wrap;
        margin-top: -10px;
        margin-right: -30px;
    }

    &__list-item {
        margin-top: 10px;
        margin-right: 30px;
        @media screen and (max-width: 500px) { margin-right: 20px; }
    }

    &__event-title,
    &__today-title,
    &__selected-title {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.6em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 500px) { font-size: 1.2rem; }
        @media screen and (max-width: 400px) { font-size: 1.1rem; }
    }

    &__event {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-orange));
    }

    &__today,
    &__selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin-right: 8px;
        border: 1px solid transparent;
        border-radius: 50%;
        font-size: 1rem;
        font-weight: 700;
    }

    &__today {
        border-color: rgb(var(--rgb-eggplant));
        color: rgb(var(--rgb-eggplant));
    }

    &__selected {
        border-color: rgb(var(--rgb-lilac));
        background-color: rgb(var(--rgb-lilac));
        color: rgb(var(--rgb-white));
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 800ms;
        cursor: pointer;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-calendar {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-calendar {
        pointer-events: all;
        visibility: visible;

        &__container {
            right: 0;
            pointer-events: all;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.3);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-calendar.closing-overlay-calendar {
    .c-overlay-calendar {
        &__container {
            right: calc(0px - var(--overlay-calendar-width));
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
