.c-radio-tag-field {
    --radio-field-title-color: rgb(var(--rgb-eggplant));
    --radio-tag-field-color: rgb(var(--rgb-eggplant));
    --radio-tag-field-background: rgb(var(--rgb-light-lilac));
    --radio-tag-field-border: rgb(var(--rgb-light-lilac));
    --radio-tag-field-color-hover: rgb(var(--rgb-white));
    --radio-tag-field-background-hover: rgb(var(--rgb-eggplant));
    --radio-tag-field-border-hover: rgb(var(--rgb-eggplant));
    --radio-tag-field-color-active: rgb(var(--rgb-white));
    --radio-tag-field-background-active: rgb(var(--rgb-eggplant));
    --radio-tag-field-border-active: rgb(var(--rgb-eggplant));
}

.c-radio-tag-field {
    position: relative;

    &__header {
        margin-bottom: var(--y-nus-30);
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
    }

    &__label {
        display: flex;
        margin: 5px;
        position: relative;
        -webkit-tap-highlight-color: transparent;
    }

    &__text {
        flex: 1;
        padding: 4px 12px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: var(--radio-tag-field-color);
        background-color: var(--radio-tag-field-background);
        border: 2px solid var(--radio-tag-field-border);
        border-radius: 100px;
        transition-property: color, background-color, border-color;
        transition-duration: 300ms;
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
    }

    &__label &__text:hover{
        color: var(--radio-tag-field-color-hover);
        background-color: var(--radio-tag-field-background-hover);
        border-color: var(--radio-tag-field-border-hover);
    }

    &__input:checked ~ &__text {
        color: var(--radio-tag-field-color-active);
        background-color: var(--radio-tag-field-background-active);
        border-color: var(--radio-tag-field-border-active);
    }

    &__input:disabled ~ &__text {
        opacity: 0.6;
        pointer-events: none;
    }
}
