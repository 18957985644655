.c-home-banner {
    --fastlinks-list-gap-x: 7.5px;
}

.c-home-banner {
    position: relative;

    &__container {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        background-color: rgb(var(--rgb-light-lilac));

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 115px;
            background-color: rgb(var(--rgb-white));
            @media screen and (max-width: 800px) { display: none; }
        }
    }

    &__shape-container {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding-right: var(--x-100);
        pointer-events: none;
        @media screen and (max-width: 800px) { padding-right: 0; }
    }

    &__shape-content {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 462px;
        padding-top: 45.2%;
    }

    &__shape {
        position: absolute;
        top: clamp(-304px, -21.4vw, -165px);
        right: clamp(-185px, -13.02vw, -110px);
        z-index: -1;
        width: clamp(400px, 48.6vw, 690px);
        height: clamp(400px, 48.6vw, 690px);
        margin-top: 2vw;
    }

    &__scrolldown-button-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 140px;
        z-index: 5;
        display: flex;
        width: var(--x-100);
        @media screen and (max-width: 800px) { display: none; }
    }

    &__scrolldown-button-sticky {
        position: sticky;
        right: 0;
        bottom: 0;
        align-self: flex-end;
        width: 100%;
    }

    &__scrolldown-button {
        position: absolute;
        right: calc(var(--x-100) / 2);
        bottom: 20px;
        translate: 50% 0;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 22px;
            height: 29px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2px;
        }
    }

    &__fastlinks-container {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        padding-right: var(--x-100);
        pointer-events: none;
        @media screen and (max-width: 800px) { display: none; }
    }

    &__fastlinks-content {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 462px;
        padding-top: 45.2%;
    }

    &__fastlinks-list-container {
        position: absolute;
        top: 0;
        right: 18px;
        left: var(--x-100);
        translate: 0 -50%;
        display: flex;
        align-items: center;
        min-width: 0;
    }

    &__fastlinks-list {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        min-width: 0;
        height: 58px;
        padding-right: var(--fastlinks-list-gap-x);
        margin-left: calc(0px - var(--fastlinks-list-gap-x));
        pointer-events: all;
    }

    &__fastlinks-list-item {
        padding: 0 var(--fastlinks-list-gap-x);
    }

    &__fastlink-button {
        width: min(100%, 335px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 30px 20px;
        border-radius: 45px;
        background-color: rgb(var(--rgb-white));
        pointer-events: all;
        transition: background-color 300ms;
        @media screen and (max-width: 800px) { box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15); }

        &:hover {
            background-color: rgb(var(--rgb-dark-eggplant));
        }

        span {
            margin-right: 10px;
            font-size: var(--fz-14);
            font-weight: 700;
            line-height: 1.4em;
            color: rgb(var(--rgb-eggplant));
            transition: color 300ms;
            @media screen and (max-width: 768px) { font-size: 14px; }
        }

        &:hover span {
            color: rgb(var(--rgb-white));
        }

        svg {
            flex-shrink: 0;
            width: 14px;
            height: 14px;
            stroke-width: 1.5px;
            stroke: rgb(var(--rgb-eggplant));
            transition: stroke 300ms;
        }

        &:hover svg {
            stroke: rgb(var(--rgb-white));
        }
    }

    .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
    }

    &__title {
        width: 100%;
        padding: max(var(--y-nus-90), 50px) var(--x-100);
        font-size: var(--fz-nus-80);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 1000px) { font-size: var(--fz-70); }
        @media screen and (max-width: 800px) { font-size: max(var(--fz-65), 4rem); }

        &.u-font-size-70 {
            font-size: var(--fz-nus-70);
            @media screen and (max-width: 1000px) { font-size: var(--fz-65); }
            @media screen and (max-width: 800px) { font-size: max(var(--fz-60), 4rem); }
        }
    }

    .swiper-pagination {
        --swiper-pagination-color: transparent;
        --swiper-pagination-left: 35px;
        --swiper-pagination-right: auto;
        --swiper-pagination-bottom: auto;
        --swiper-pagination-top: 50%;
        --swiper-pagination-bullet-size: 18px;
        --swiper-pagination-bullet-width: 18px;
        --swiper-pagination-bullet-height: 18px;
        --swiper-pagination-bullet-inactive-color: transparent;
        --swiper-pagination-bullet-inactive-opacity: 0.5;
        --swiper-pagination-bullet-opacity: 1;
        --swiper-pagination-bullet-horizontal-gap: 0px;
        --swiper-pagination-bullet-vertical-gap: 0px;
        --swiper-pagination-bullet-border-radius: 2px;

        transform: none!important;
        translate: 0 -50%;
        text-align: left;
        pointer-events: all;
    }

    .swiper-pagination-bullet {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            width: 10px;
            height: 10px;
            background-color: rgb(var(--rgb-white));
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        }
    }

    &__picture-container {
        position: relative;
        width: 100%;
        min-height: 462px;
        padding-right: var(--x-100);
        @media screen and (max-width: 800px) { padding-right: 0; }
    }

    &__picture {
        position: relative;
        width: 100%;
        min-height: 100%;
        padding-top: 45.2%;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__sticky-container {
        position: absolute;
        top: calc(0px - var(--header-height) - var(--alerts-height));
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
        transition: top var(--alerts-transition-duration) var(--alerts-transition-timing-function);
        @media screen and (min-width: 701px) { display: none; }
    }

    &__sticky-element {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: calc((var(--vh, 1vh) * 100));
        max-height: 100%;
        padding: 0 22px 25px;
    }

    &__credits {
        position: absolute;
        right: calc(32px + var(--x-100));
        bottom: 15px;
        padding: 2px;
        @media screen and (max-width: 800px) { right: 15px; }
        @media screen and (max-width: 440px) {
            top: 15px;
            bottom: initial;
        }
    }
}