@use "../base/typography";

.c-publications-component {
    --publications-list-gap-x: 25px;
    @media screen and (max-width: 1000px) { --publications-list-gap-x: 15px; }
    --publications-list-gap-y: 30px;
}

.c-publications-component {
    &__top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 0;
    }

    &__title {
        font-size: max(var(--fz-60), 4.5rem);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-1;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
        translate: 0 1px;
        width: fit-content;
        @media screen and (max-width: 900px) { display: none; }

        &.--mobile {
            display: none;
            margin-top: 40px;
            @media screen and (max-width: 900px) { display: flex; }
            @media screen and (max-width: 400px) {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--publications-list-gap-x) * 2));
        min-width: 0;
        padding-top: 40px;
        margin-right: calc(0px - var(--publications-list-gap-x));
        margin-bottom: calc(0px - var(--publications-list-gap-y));
        margin-left: calc(0px - var(--publications-list-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--publications-list-gap-x);
        margin-bottom: var(--publications-list-gap-y);
        @media screen and (max-width: 1200px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }
}