.o-plus {
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        will-change: transform;
    }
    
    &::before {
        transform: translate3d(-50%, -50%, 0);
    }
    
    &::after {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
}