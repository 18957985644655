@use 'sass:math';

:root {
    // Générales
    --calendar-header-background-color: rgb(var(--rgb-white));
    --calendar-header-color: rgb(var(--rgb-eggplant));
    --calendar-main-background-color: rgb(var(--rgb-white));
    --calendar-main-color: rgb(var(--rgb-eggplant));
    --calendar-main-day-cell-size: 4.6rem;
    @media screen and (max-width: 1024px) { --calendar-main-day-cell-size: 4.4rem; }
    @media screen and (max-width: 700px) { --calendar-main-day-cell-size: 4rem; }
    @media screen and (max-width: 500px) { --calendar-main-day-cell-size: 3.4rem; }
    @media screen and (max-width: 400px) { --calendar-main-day-cell-size: 3rem; }
    --calendar-main-month-year-cell-size: calc(var(--calendar-main-day-cell-size) * 1.7);

    // Dates des mois précédents ou suivant (.prev, .next)
    --calendar-day-previous-or-next-color: rgba(var(--rgb-gray), 0.5);
    --calendar-day-previous-or-next-background-color: rgb(var(--rgb-white));
    --calendar-day-previous-or-next-border-color: rgb(var(--rgb-white));
    --calendar-day-previous-or-next-dot-or-underline-background-color: rgba(var(--rgb-orange), 0.5);
    --calendar-month-year-previous-or-next-color: rgba(var(--rgb-gray), 0.5);


    // Date avec événement (:not(.disabled))
    --calendar-day-color: rgb(var(--rgb-eggplant));
    --calendar-day-background-color: rgb(var(--rgb-white));
    --calendar-day-border-color: rgb(var(--rgb-white));
    --calendar-day-dot-or-underline-background-color: rgb(var(--rgb-orange));
    --calendar-month-year-color: rgb(var(--rgb-eggplant));

    --calendar-day-color-hover: rgb(var(--rgb-eggplant));
    --calendar-day-background-color-hover: rgb(var(--rgb-light-lilac));
    --calendar-day-border-color-hover: rgb(var(--rgb-light-lilac));
    --calendar-day-dot-or-underline-background-color-hover: rgb(var(--rgb-orange));
    --calendar-month-year-background-color-hover: rgb(var(--rgb-light-lilac));


    // Date d'aujourd'hui
    --calendar-today-color: rgb(var(--rgb-eggplant));
    --calendar-today-background-color: rgb(var(--rgb-white));
    --calendar-today-border-color: rgb(var(--rgb-eggplant));
    --calendar-today-dot-or-underline-background-color: rgb(var(--rgb-orange));

    --calendar-today-color-hover: rgb(var(--rgb-eggplant));
    --calendar-today-background-color-hover: rgb(var(--rgb-light-lilac));
    --calendar-today-border-color-hover: rgb(var(--rgb-light-lilac));
    --calendar-today-dot-or-underline-background-color-hover: rgb(var(--rgb-orange));


    // Date sélectionnée
    --calendar-day-active-color: rgb(var(--rgb-white));
    --calendar-day-active-background-color: rgb(var(--rgb-lilac));
    --calendar-day-active-border-color: rgb(var(--rgb-lilac));
    --calendar-day-active-dot-or-underline-background-color: rgb(var(--rgb-white));

    --calendar-day-active-color-hover: rgb(var(--rgb-eggplant));
    --calendar-day-active-background-color-hover: rgb(var(--rgb-light-lilac));
    --calendar-day-active-border-color-hover: rgb(var(--rgb-light-lilac));
    --calendar-day-active-dot-or-underline-background-color-hover: rgb(var(--rgb-orange));
}

// Calendrier en dropdown dans champ date
.datepicker-dropdown {
    position: absolute;
    top: calc(100% + 15px) !important;
    left: 0 !important;
    width: 475px;
    padding: 20px;
    background-color: rgb(var(--rgb-white));
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 300ms, visibility 0ms ease 300ms;
    z-index: 100;

    @media screen and (max-width: 535px) {
        width: 100%;
        padding: 20px 10px;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: opacity 300ms;
    }

    .datepicker-picker .day::after {
        display: none;
    }
}

.datepicker-picker {
    display: inline-block;

    span {
        display: block;
        flex: 1;
        border: 0;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .datepicker-header {
        background-color: var(--calendar-header-background-color);
        padding-bottom: 28px;
        border-bottom: 1px solid rgba(var(--rgb-eggplant), 0.5);
    }

    .datepicker-main {
        background-color: var(--calendar-main-background-color);
        padding-top: 32px;
    }
}

.datepicker-controls {
    display: flex;
    min-width: 0;

    .button {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        min-width: 0;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 700;
        line-height: 1.3em;
        color: var(--calendar-header-color);

        &:focus,
        &:active {
            outline: none;
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn {
        justify-content: flex-start;
    }

    .next-btn {
        justify-content: flex-end;
    }

    .prev-btn,
    .next-btn {
        width: percentage(math.div(1, 7));
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        >svg {
            width: 11px;
            height: 9px;
            fill: rgb(var(--rgb-eggplant));
        }

        &.disabled {
            visibility: hidden;
        }
    }

    .prev-btn>svg {
        transform: rotate(90deg);
    }

    .next-btn>svg {
        transform: rotate(-90deg);
    }
}

.datepicker-view {
    display: flex;
    min-width: 0;

    .days-of-week {
        display: flex;
        min-width: 0;
    }

    .dow {
        position: relative;
        height: var(--calendar-main-day-cell-size);
        line-height: var(--calendar-main-day-cell-size);
        padding-top: 1px;
        margin-bottom: 22px;
        font-size: min(var(--fz-17), 1.7rem);
        font-weight: 700;
        color: var(--calendar-main-color);
        z-index: 1;
        @media screen and (max-width: 700px) { font-size: 1.5rem; }
        @media screen and (max-width: 500px) { font-size: 1.4rem; }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: var(--calendar-main-day-cell-size);
            height: var(--calendar-main-day-cell-size);
            background-color: rgb(var(--rgb-white));
            border: 1px solid rgb(var(--rgb-white));
            border-radius: 50%;
            z-index: -1;
        }
    }
}

.datepicker-grid {
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
}

.datepicker-cell {
    height: var(--calendar-main-day-cell-size);
    line-height: var(--calendar-main-day-cell-size);

    &.day {
        position: relative;
        padding-top: 1px;
        margin-bottom: 22px;
        font-size: min(var(--fz-17), 1.7rem);
        font-weight: 700;
        color: var(--calendar-main-color);
        z-index: 1;
        @media screen and (max-width: 700px) { font-size: 1.5rem; }
        @media screen and (max-width: 500px) { font-size: 1.4rem; }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: var(--calendar-main-day-cell-size);
            height: var(--calendar-main-day-cell-size);
            background-color: rgb(var(--rgb-white));
            border: 1px solid rgb(var(--rgb-white));
            border-radius: 50%;
            transition: background-color 300ms, border-color 300ms;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 6px;
            left: 50%;
            transform: translateX(-50%);
            width: 5px;
            height: 5px;
            background-color: transparent;
            border-radius: 50%;
            transition: background-color 0.3s;
            z-index: 1;

            @media screen and (max-width: 500px) { bottom: 2px; }
        }
    }

    &.month,
    &.year,
    &.decade {
        font-size: var(--fz-16);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .1em;
        color: var(--calendar-main-color);
        transition: background-color 0.3s;
    }

    &.disabled {
        cursor: default;

        &::after {
            display: none;
        }
    }

    // Date avec événement (:not(.disabled))
    &:not(.disabled) {
        cursor: pointer;

        &::after {
            background-color: var(--calendar-day-dot-or-underline-background-color);
        }

        &:hover {
            &::before {
                background-color: var(--calendar-day-background-color-hover);
                border-color: var(--calendar-day-border-color-hover);
            }
        }
    }

    // Date d'aujourd'hui
    &.day.today:not(.selected) {
        color: var(--calendar-today-color);

        &::before {
            background-color: var(--calendar-today-background-color);
            border-color: var(--calendar-today-border-color);
        }

        &::after {
            background-color: var(--calendar-today-dot-or-underline-background-color);
        }

        &:hover:not(.disabled) {
            color: var(--calendar-today-color-hover);

            &::before {
                background-color: var(--calendar-today-background-color-hover);
                border-color: var(--calendar-today-border-color-hover);
            }

            &::after {
                background-color: var(--calendar-today-dot-or-underline-background-color-hover);
            }
        }
    }

    // Date sélectionnée
    &.day.selected {
        color: var(--calendar-day-active-color);

        &::before {
            background-color: var(--calendar-day-active-background-color);
            border-color: var(--calendar-day-active-border-color);
        }

        &::after {
            background-color: var(--calendar-day-active-dot-or-underline-background-color);
        }

        &:hover {
            color: var(--calendar-day-active-color-hover);

            &::before {
                background-color: var(--calendar-day-active-background-color-hover);
                border-color: var(--calendar-day-active-border-color-hover);
            }

            &::after {
                background-color: var(--calendar-day-active-dot-or-underline-background-color-hover);
            }
        }
    }

    // Dates des mois précédents ou suivant (.prev, .next)
    &.day.prev,
    &.day.next {
        color: var(--calendar-day-previous-or-next-color);

        &::before {
            background-color: var(--calendar-day-previous-or-next-background-color);
            border-color: var(--calendar-day-previous-or-next-border-color);
        }

        &::after {
            background-color: var(--calendar-day-previous-or-next-dot-or-underline-background-color);
        }
    }

    &.month,
    &.year,
    &.decade {
        &.prev,
        &.next {
            color: var(--calendar-month-year-previous-or-next-color);
        }

        &.selected,
        &:hover {
            background-color: var(--calendar-month-year-background-color-hover);
        }
    }
}

.datepicker-view {
    .days .datepicker-cell {
        flex-basis: percentage(math.div(1, 7));
    }

    &.months .datepicker-cell,
    &.years .datepicker-cell,
    &.decades .datepicker-cell {
        flex-basis: 25%;
        height: var(--calendar-main-month-year-cell-size);
        line-height: var(--calendar-main-month-year-cell-size);
    }
}
