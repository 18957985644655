.c-button-fastlink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 30px;
    border-radius: 45px;
    transition: background-color 300ms;

    &.--orange { background-color: rgb(var(--rgb-orange)); }
    &.--light-orange { background-color: rgb(var(--rgb-light-orange)); }
    &.--green { background-color: rgb(var(--rgb-green)); }
    &.--yellow { background-color: rgb(var(--rgb-yellow)); }
    &.--lilac { background-color: rgb(var(--rgb-lilac)); }
    &.--blue { background-color: rgb(var(--rgb-blue)); }

    &:hover {
        background-color: rgb(var(--rgb-dark-eggplant));
    }

    span {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4em;
        color: rgb(var(--rgb-eggplant));
        transition: color 300ms;
    }

    &:hover span {
        color: rgb(var(--rgb-white));
    }

    svg {
        translate: 0 -1px;
        flex-shrink: 0;
        width: 22px;
        height: 22px;
        fill: none;
        stroke: rgb(var(--rgb-eggplant));
        stroke-width: 7px;
        transition: stroke 300ms;
    }

    &:hover svg {
        stroke: rgb(var(--rgb-white));
    }

    &.--button {
        width: 224px;
        justify-content: center;
        flex-shrink: 0;
        background-color: rgb(var(--rgb-eggplant));
        pointer-events: all;

        &:hover {
            background-color: rgb(var(--rgb-dark-eggplant));
        }

        span {
            color: rgb(var(--rgb-white));
        }

        svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-white));
            stroke-width: 1.5px;
        }
    }
}