@use "../abstract/placeholders";
@use "../base/typography";

.c-showcase {
    --white-space-height: 60px;
}

.c-showcase {
    &__container {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 0;
        background-color: rgb(var(--rgb-eggplant));
        @media screen and (max-width: 800px) { flex-direction: column; }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: var(--white-space-height);
            background-color: rgb(var(--rgb-white));
            @media screen and (max-width: 800px) { display: none; }
        }
    }

    &__text-block {
        position: relative;
        overflow: hidden;
        isolation: isolate;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        min-width: 0;
        min-height: 450px;
        padding: calc(var(--white-space-height) + var(--y-125)) var(--x-100) var(--y-125);
        @media screen and (max-width: 800px) {
            order: 2;
            width: 100%;
            padding: var(--y-125) var(--x-100);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: var(--white-space-height);
            background-color: rgb(var(--rgb-white));
            @media screen and (max-width: 800px) { display: none; }
        }
    }

    &__category {
        margin-bottom: 10px;
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: 1.3em;
        color: rgba(var(--rgb-white), 0.7);
    }

    &__title {
        margin-bottom: var(--y-40);
        font-size: var(--fz-60);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-2;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
    }

    &__shape {
        position: absolute;
        bottom: 0;
        left: -66px;
        z-index: -1;
        width: clamp(518px,47.92%,690px);
        height: clamp(517px,47.36vw,689px);
        @media screen and (max-width: 800px) {
            top: -20px;
            bottom: initial;
            left: -55px;
        }
    }

    &__picture-container {
        position: relative;
        flex-shrink: 0;
        width: 640px;
        padding-bottom: var(--y-125);
        @media screen and (max-width: 1300px) { width: 540px; }
        @media screen and (max-width: 1100px) { width: 440px; }
        @media screen and (max-width: 1000px) { width: 340px; }
        @media screen and (max-width: 800px) {
            order: 1;
            width: 100%;
            padding-bottom: 0;
        }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 83.3%;
        border-bottom-left-radius: 100px;
        @extend %border-radius;
        @media screen and (max-width: 800px) { border-bottom-left-radius: 0; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__svg-container {
        position: absolute;
        top: calc(var(--white-space-height) + 75px);
        left: 0;
        translate: -50% 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        min-width: 0;
        height: 100px;
        border-radius: 50%;
        background-color: rgb(var(--rgb-orange));
        @media screen and (max-width: 1300px) {
            width: 80px;
            height: 80px;
        }
        @media screen and (max-width: 800px) { display: none; }

        svg {
            width: 30px;
            height: 30px;
            fill: none;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 7px;
        }
    }
}