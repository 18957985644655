.c-pagination {
    &__container {
        display: flex;
        justify-content: center;
        width: 100%;
        min-width: 0;
        padding: 6px 11px;
    }

    &__button {
        display: block;
        will-change: transform;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        svg {
            width: 14px;
            height: 15px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2px;
        }

        &.--prev {
            rotate: -90deg;
        }

        &.--next {
            rotate: 90deg;
        }

        &.--inactive {
            pointer-events: none;
            opacity: 0.6;

            svg {
                stroke: rgb(var(--rgb-gray));
            }
        }
    }

    &__list {
        display: flex;
        align-items: center;
        min-width: 0;
        padding-right: var(--x-80);
        padding-left: var(--x-80);
    }

    &__list-item:not(:last-child) {
        margin-right: 4px;
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        min-width: 0;
        height: 38px;
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }

        &.--active {
            pointer-events: none;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            min-width: 0;
            height: 26px;
            padding-top: 2px;
            border-radius: 50%;
            font-size: var(--fz-15);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
        }

        &.--active span {
            background-color: rgb(var(--rgb-eggplant));
            color: rgb(var(--rgb-white));
        }
    }
}