@use "../base/typography";

.c-publications {
    --publications-list-gap-x: 25px;
    @media screen and (max-width: 1000px) { --publications-list-gap-x: 15px; }
    --publications-list-gap-y: 65px;
    @media screen and (max-width: 600px) { --publications-list-gap-y: 30px; }
}

.c-publications {
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: calc(100% + (var(--publications-list-gap-x) * 2));
        min-width: 0;
        margin-right: calc(0px - var(--publications-list-gap-x));
        margin-bottom: calc(0px - var(--publications-list-gap-y));
        margin-left: calc(0px - var(--publications-list-gap-x));
    }

    &__list-item {
        width: 33.33%;
        padding: 0 var(--publications-list-gap-x);
        margin-bottom: var(--publications-list-gap-y);
        @media screen and (max-width: 1200px) { width: 50%; }
        @media screen and (max-width: 700px) { width: 100%; }
    }
}