.c-button-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: var(--button-calendar-dimensions);
    min-width: 0;
    height: var(--button-calendar-dimensions);
    border-radius: 50%;
    background-color: rgb(var(--rgb-eggplant));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-dark-eggplant));
    }

    svg {
        width: 20px;
        height: 20px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 2px;
    }
}