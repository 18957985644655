@use "../abstract/placeholders";
@use "../base/typography";

.c-page-title {
    --shapes-width: 114px;
}

.c-page-title {
    padding: max(var(--y-90), 40px) calc(var(--shapes-width) + 50px) 0 var(--x-100);
    @media screen and (max-width: 600px) { padding-right: var(--x-100); }

    &:has(.c-page-title__picture-container) {
        position: relative;
        isolation: isolate;
        padding-right: var(--x-100);
        @media screen and (max-width: 700px) {
            padding-right: 0;
            padding-left: 0;
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            height: 145px;
            background-color: rgb(var(--rgb-white));
        }
    }

    &__breadcrumb {
        margin-bottom: max(var(--y-25), 20px);

        .c-breadcrumb__button {
            display: none;
        }
    }

    &:has(.c-page-title__picture-container) &__breadcrumb {
        @media screen and (max-width: 700px) {
            padding-right: var(--x-100);
            padding-left: var(--x-100);
        }
    }

    &__text-block {
        padding-bottom: max(var(--y-90), 50px);
    }

    &:has(.c-page-title__select-container) &__text-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-width: 0;
        @media screen and (max-width: 900px) {
            flex-direction: column;
            align-items: initial;
            justify-content: initial;
        }
    }

    &:has(.c-page-title__picture-container) &__text-block {
        padding-bottom: 0;
        margin-bottom: var(--y-80);
        @media screen and (max-width: 700px) {
            padding-right: var(--x-100);
            padding-left: var(--x-100);
        }
        @media screen and (max-width: 500px) { margin-bottom: 35px; }
    }

    &:has(.c-page-title__event-infos) &__text-block {
        padding-bottom: 0;
        margin-bottom: 30px;
    }

    &__title {
        font-size: max(var(--fz-70), 4rem);
        font-weight: 700;
        line-height: 1.3em;
        color: rgb(var(--rgb-eggplant));

        &.u-font-size-65 {
            font-size: var(--fz-65);
            @media screen and (max-width: 600px) { font-size: var(--fz-60); }
        }
    }

    &:has(.c-page-title__select-container) &__title {
        @media screen and (max-width: 900px) { margin-bottom: 20px; }
    }

    &__shapes,
    &__picture-shapes {
        position: absolute;
        top: min(var(--y-50), 50px);
        right: 0;
        display: flex;
        height: calc(100% + min(var(--y-25), 25px));
        width: var(--shapes-width);
        min-width: 0;
        @media screen and (max-width: 700px) { display: none; }
    }

    &__picture-shapes {
        top: 0;
        height: calc(100% + min(var(--y-50), 50px));
    }

    &__shape {
        position: relative;
        width: 28px;
        height: 100%;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: min(var(--y-50), 50px);
            left: 0;
            background-color: rgb(var(--rgb-eggplant));
        }

        &:not(:last-child) {
            margin-right: 15px;
        }

        &:nth-child(2)::before {
            top: min(var(--y-50), 50px);
            bottom: 0;
            background-color: rgb(var(--rgb-green));
        }

        &.--orange::before          { background-color: rgb(var(--rgb-orange)); }
        &.--light-orange::before    { background-color: rgb(var(--rgb-light-orange)); }
        &.--green::before           { background-color: rgb(var(--rgb-green)); }
        &.--yellow::before          { background-color: rgb(var(--rgb-yellow)); }
        &.--lilac::before           { background-color: rgb(var(--rgb-lilac)); }
        &.--blue::before            { background-color: rgb(var(--rgb-blue)); }
    }

    &__picture-shapes &__shape::before {
        bottom: 0;
        height: calc(100% - min(var(--y-50), 50px));
    }

    &:has(.c-page-title__picture-container) &__shapes {
        display: none;
    }

    &__select-container,
    &__search-field-container {
        position: relative;

        .tail-select {
            position: relative;
            z-index: 1;
            flex-shrink: 0;
            width: 225px;
            transition-property: z-index;

            &.active { z-index: 2; }
            &:not(.active) { transition-delay: 500ms; }

            svg {
                position: absolute;
                top: 50%;
                right: 35px;
                translate: 0 -50%;
                z-index: 1;
                width: 14px;
                height: 14px;
                fill: rgb(var(--rgb-white));
                pointer-events: none;
                transition: rotate 500ms var(--out-cubic);
            }
        }

        .tail-select.active svg { rotate: -180deg; }

        .select-label {
            position: relative;
            z-index: 1;
            padding: 20px 60px 20px 35px;
            border: 0;
            border-radius: 35px;
            background-color: rgb(var(--rgb-eggplant));
            cursor: pointer;
            user-select: none;
            transition: background-color 500ms;

            &:hover { background-color: rgb(var(--rgb-dark-eggplant)); }
        }

        .tail-select.active .select-label { background-color: rgb(var(--rgb-dark-eggplant)); }

        .label-inner {
            position: relative;
            overflow: hidden;
            display: block;
            font-family: var(--ff);
            font-size: var(--fz-14);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .select-dropdown {
            position: absolute;
            z-index: -1;
            top: 0%;
            left: 0;
            opacity: 0;
            overflow: hidden!important;
            width: 100%;
            background-color: rgb(var(--rgb-white));
            border-radius: 30px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            visibility: hidden;
            pointer-events: none;
            transition: top 500ms var(--out-cubic), opacity 500ms var(--out-cubic), visibility 0ms linear 500ms;
        }

        .tail-select.active .select-dropdown {
            top: calc(100% + 10px);
            visibility: visible;
            opacity: 1;
            pointer-events: all;
            transition: top 500ms var(--out-cubic), opacity 500ms var(--out-cubic), visibility 0ms linear 0ms;
        }

        .dropdown-inner {
            overflow: auto;
            max-height: 230px;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: rgb(var(--rgb-white));
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgb(var(--rgb-eggplant));
            }
        }

        .dropdown-option {
            padding: 15px 35px;
            background-color: rgb(var(--rgb-white));
            font-family: var(--ff);
            font-size: var(--fz-14);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
            cursor: pointer;
            user-select: none;
            transition: background-color 300ms;

            &:hover { background-color: rgb(var(--rgb-light-lilac)); }

            &.selected { color: rgba(var(--rgb-eggplant), 0.5); }
        }

        select { // Select natif
            overflow: hidden;
            flex-shrink: 0;
            width: 225px;
            padding: 20px 60px 20px 35px;
            font-family: var(--ff);
            font-size: var(--fz-14);
            font-weight: 700;
            line-height: 1.3em;
            color: rgb(var(--rgb-white));
            border: 0;
            outline: none;
            border-radius: 35px;
            background-image: url("../medias/images/icons/pointer-white.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 35px) 50%;
            background-size: 14px 14px;
            background-color: rgb(var(--rgb-eggplant));
            white-space: nowrap;
            text-overflow: ellipsis;
            -webkit-appearance: none;

            @media screen and (max-width: 350px) { width: 200px; }
            @media screen and (max-width: 325px) { width: 185px; }
            @media screen and (max-width: 300px) { width: 160px; }
        }

        select::-ms-expand { display: none; }
    }

    &:has(.c-button-calendar) &__select-container {
        display: flex;
        align-items: center;
        min-width: 0;
    }

    .tail-select + .c-button-calendar,
    select + .c-button-calendar {
        margin-left: 20px;

        @media screen and (max-width: 350px) { margin-left: 10px; }
    }

    &__search-field-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-width: 0;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: initial;
            justify-content: initial;
        }

        .c-search-field {
            width: 100%;
            max-width: 566px;
            margin-right: 30px;
            position: relative;
            @media screen and (max-width: 800px) {
                margin-right: 0;
                margin-bottom: 20px;
            }

            .c-search-field__input {
                width: 100%;
                height: 100%;
                min-height: 58px;
                padding: 15px 80px 15px 30px;
                border: none;
                border-radius: 40px;
                background-color: rgb(var(--rgb-white));
                font-family: var(--ff);
                font-size: 1.6em;
                font-weight: 500;
                line-height: 1.3em;
                color: rgb(var(--rgb-eggplant));
                @media screen and (max-width: 500px) { padding: 12px 72px 12px 22px; }

                &::-webkit-input-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
                &::-moz-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
                &:-ms-input-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
                &:-moz-placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
                &::placeholder { color: rgba(var(--rgb-eggplant), 0.6); }
            }

            .c-search-field__search-button {
                position: absolute;
                top: 50%;
                right: 30px;
                translate: 0 calc(-50% - 3px);
                width: 13px;
                height: 13px;
                @media screen and (max-width: 500px) {
                    right: 22px;
                    translate: 0 calc(-50% - 2px);
                }

                svg {
                    width: 100%;
                    height: 100%;
                    stroke: rgb(var(--rgb-eggplant));
                    stroke-width: 2px;
                    transition: opacity 300ms;
                }

                &:hover svg {
                    opacity: 0.6;
                }
            }

            .c-search-field__x {
                position: absolute;
                top: 50%;
                right: 51px;
                translate: 0 calc(-50% - 2px);
                display: block;
                width: 12px;
                height: 13px;
                opacity: 0;
                pointer-events: none;
                transition: opacity 300ms;
                font-family: initial;

                svg {
                    width: 100%;
                    height: 100%;
                    stroke: rgb(var(--rgb-eggplant));
                    stroke-width: 3px;
                    transition: opacity 300ms;
                }

                &:hover svg {
                    opacity: 0.6;
                }
            }

            &[data-inputvalue]:not([data-inputvalue=""]) .c-search-field__x {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &__title + &__search-field-container {
        margin-top: 20px;
    }

    &__event-infos {
        width: calc(100% + (var(--x-100) * 2));
        padding: 17px var(--x-100);
        margin: 0 calc(0px - var(--x-100)) var(--y-80) calc(0px - var(--x-100));
        background-color: rgb(var(--rgb-background-purple));
        @media screen and (max-width: 700px) {
            width: 100%;
            margin: 0 0 var(--y-80) 0;
        }
    }

    &__event-infos-list,
    &__event-infos-list-item {
        display: flex;
        align-items: center;
        min-width: 0;
    }

    &__event-infos-list {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: -15px;
    }

    &__event-infos-list-item {
        margin-bottom: 15px;

        &:not(:last-child) {
            margin-right: var(--x-40);
        }

        svg {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-eggplant));
            stroke-width: 2.5px;
            margin-right: 15px;
        }

        a, span {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-eggplant));
        }

        a {
            padding-bottom: 2px;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-orange));
            transition: box-shadow 300ms var(--out-cubic);

            &:hover {
                box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-eggplant));
            }
        }

    }

    &__picture-container {
        position: relative;
        width: 100%;
        padding-right: calc(17px + var(--shapes-width));
        @media screen and (max-width: 700px) { padding-right: 0; }
    }

    &__scrolldown-button {
        @extend .c-button;
        @extend .c-button--full-1;
        @extend .c-button--svg-on-the-right;
        @extend .c-button--svg-both;
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 1;
        translate: 0 -50%;
        @media screen and (max-width: 700px) { display: none; }

        svg {
            rotate: 180deg;
        }
    }

    &__picture {
        position: relative;
        width: 100%;
        padding-top: 55.4%;
        border-bottom-left-radius: 95px;
        @extend %border-radius;
        @media screen and (max-width: 700px) { border-bottom-left-radius: 0; }
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__credits {
        position: absolute;
        right: calc(32px + var(--shapes-width));
        bottom: 15px;
        padding: 2px;
        @media screen and (max-width: 700px) { right: 15px; }
    }
}